import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { isValidElement, useEffect, useRef } from "react";
import { isArray } from "lodash";
import Modal from "react-modal";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

import { nanoid } from "nanoid";

import IconOnlyButton from "~/components/buttons/IconOnlyButton";

import { h3, h4 } from "~/styles/typographyStyles";
import { screenReaderText } from "~/styles/screenReaderText";

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 2.5rem;
`;

const Header = styled.div`
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.borderGray}`};
    padding: 20px 0;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
`;

const Title = styled.h1`
    ${h3};
    margin-bottom: 0;
    width: 100%;
`;

const Subtitle = styled.h2`
    ${h4};
    @media (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
        font-size: 1.8rem;
    }
`;

const HiddenDialogHeading = styled.h2`
    ${screenReaderText}
`;

const getAriaProps = (props, isOpen) => {
    let ariaProps = {};
    if (isOpen) {
        ariaProps = props.aria || {};
        ariaProps.labelledby = "modalTitle";
        ariaProps.live = "assertive";
    }
    return ariaProps;
};

const BaseModal = ({
    children,
    formTitle = "",
    title = "",
    subtitle = "",
    onRequestClose = () => {},
    large = false,
    small = false,
    isOpen,
    isCookieWall = false,
    overlayStyles = {},
    contentStyles = {},
    showIconButton = true,
    ...props
}) => {
    const content = useRef(null);

    const ModalStyles = {
        overlay: {
            zIndex: 100,
            ...overlayStyles,
        },
        content: {
            width: `100%`,
            left: `50%`,
            top: `50%`,
            right: `auto`,
            bottom: `auto`,
            transform: `translate(-50%, -50%)`,
            padding: `35px`,
            maxWidth: large ? `1300px` : small ? `525px` : `900px`,
            maxHeight: `90%`,
            margin: ` 0 auto`,
            scrollBehavior: "smooth",
            ...contentStyles,
        },
    };

    useEffect(() => {
        Modal.setAppElement(
            document.querySelector("#root") ||
                document.querySelector("#___gatsby"),
        );
    }, []);

    const disableScrollAndSetFocus = () => {
        if (content.current) {
            disableBodyScroll(content.current);
            document.documentElement.style.overflow = "hidden";
        }
    };

    const closeModal = () => {
        onRequestClose();
    };

    const enableScroll = () => {
        clearAllBodyScrollLocks();
        document.documentElement.style.overflow = null;
    };

    const childArray = isArray(children) ? children : [children];

    const childrenWithProp = childArray.map((child) =>
        isValidElement(child)
            ? React.cloneElement(child, { closeModal, key: nanoid() })
            : null,
    );

    props.aria = getAriaProps(props, isOpen);

    const modalProps = {
        isOpen,
        onAfterOpen: () => disableScrollAndSetFocus(),
        onAfterClose: () => enableScroll(),
        style: ModalStyles,
        shouldCloseOnEsc: !isCookieWall,
        shouldCloseOnOverlayClick: !isCookieWall,
        contentRef: (node) => (content.current = node),
        ...props,
    };

    if (!isCookieWall) {
        modalProps.onRequestClose = () => closeModal();
    }

    return (
        <Modal {...modalProps}>
            <HiddenDialogHeading id="modalTitle">
                {formTitle || title || "Dialog Open"}
            </HiddenDialogHeading>
            {showIconButton ? (
                <ButtonWrapper>
                    <IconOnlyButton
                        onClick={() => closeModal()}
                        iconLibrary="fas"
                        icon="times"
                    />
                </ButtonWrapper>
            ) : null}
            {title ? (
                <Header>
                    <Title>{title}</Title>
                </Header>
            ) : null}
            {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
            {childrenWithProp}
        </Modal>
    );
};

BaseModal.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    large: PropTypes.bool,
    small: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]).isRequired,
    formTitle: PropTypes.string,
    onRequestClose: PropTypes.func,
    isOpen: PropTypes.bool.isRequired,
    isCookieWall: PropTypes.bool,
    overlayStyles: PropTypes.shape({}),
    contentStyles: PropTypes.shape({}),
    showIconButton: PropTypes.bool,
};

export default BaseModal;
