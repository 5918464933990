import React from "react";
import { nanoid } from "nanoid";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

import { FilterWrapper } from "~/components/FilterSection";
import ButtonGroup from "~/components/layout/ButtonGroup";
import ContentContainer from "~/components/layout/ContentContainer";

const SkeletonGroup = styled.div`
    margin-top: 4rem;
`;

const SideBarSticky = styled.div`
    margin-bottom: 20px;

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
        position: sticky;
        top: 0;
        -ms-grid-column: 1;
        grid-column: 1;
        align-self: flex-start;
    }
`;

const BaseSkeleton = styled.div`
    background-color: ${({ theme }) => theme.palette.grayThree};
    width: ${({ width }) => width ?? "100%"};
    height: ${({ height }) => height ?? "1rem"};
`;

const SkeletonText = styled(BaseSkeleton)`
    border-radius: 0.125rem;
    margin-bottom: 10px;
`;

const SkeletonSearchBar = styled(BaseSkeleton)`
    border-radius: 100px;
    margin-bottom: 3rem;
`;

const SkeletonButton = styled(BaseSkeleton)`
    border-radius: ${({ theme, borderRadius }) =>
        borderRadius ?? theme.button.borderRadiusLarge};
`;

const BlockWrapper = styled(ContentContainer)`
    padding: 3rem 0;
`;

const BlockTop = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
`;

const BlockTopLines = styled.div`
    flex: 1;
`;

const BlockTopLine = styled(SkeletonText)`
    max-width: ${({ maxWidth }) => maxWidth ?? "100%"};
`;

const SkeletonFilterGroup = ({ rows }) => {
    const skeletonText = [];

    for (let i = 1; i <= rows; i++) {
        const id = nanoid();

        if (i === 1) {
            skeletonText.push(
                <SkeletonText key={id} width="60%" height="2rem" />,
            );
        } else if (i === rows) {
            skeletonText.push(<SkeletonText key={id} width="80%" />);
        } else {
            skeletonText.push(<SkeletonText key={id} />);
        }
    }

    return <SkeletonGroup>{skeletonText}</SkeletonGroup>;
};

const SkeletonBlock = () => {
    return (
        <BlockWrapper>
            <BlockTop>
                <SkeletonButton
                    borderRadius="100%"
                    width="100px"
                    height="100px"
                />
                <BlockTopLines>
                    <BlockTopLine maxWidth="300px" height="1rem" />
                    <BlockTopLine maxWidth="600px" height="1rem" />
                    <BlockTopLine maxWidth="900px" height="1rem" />
                    <BlockTopLine height="1rem" />
                </BlockTopLines>
            </BlockTop>
            <SkeletonFilterGroup rows={25} />
        </BlockWrapper>
    );
};

const SkeletonSidebar = () => {
    return (
        <SideBarSticky>
            <FilterWrapper>
                <SkeletonText width="60%" height="2rem" />
                <ButtonGroup>
                    <SkeletonButton width="116px" height="50px" />
                    <SkeletonButton width="116px" height="50px" />
                    <SkeletonButton width="116px" height="50px" />
                    <SkeletonButton width="116px" height="50px" />
                </ButtonGroup>
                <SkeletonFilterGroup rows={12} />
                <SkeletonFilterGroup rows={12} />
                <SkeletonFilterGroup rows={12} />
            </FilterWrapper>
        </SideBarSticky>
    );
};

const SkeletonTable = ({ rows }) => {
    const skeletonText = [];

    for (let i = 1; i <= rows; i++) {
        const id = nanoid();

        if (i === 1) {
            skeletonText.push(
                <SkeletonSearchBar key={id} height="50px" width="100%" />,
            );
        } else {
            skeletonText.push(<SkeletonText key={id} height="3rem" />);
        }
    }

    return <div>{skeletonText}</div>;
};

const Skeleton = ({ variant, ...rest }) => {
    switch (variant) {
        case "sidebar":
            return <SkeletonSidebar />;
        case "table":
            return <SkeletonTable rows={25} />;
        case "block":
            return <SkeletonBlock />;
        default:
            return <BaseSkeleton {...rest} />;
    }
};

export default Skeleton;

Skeleton.propTypes = {
    height: "1rem",
    width: "100%",
    variant: "",
};

Skeleton.propTypes = {
    variant: PropTypes.oneOf(["sidebar", "table", "block"]),
    height: PropTypes.string,
    width: PropTypes.string,
};
