import { merge } from "lodash";
import { isBrowser } from "~/utilities/helpers";

export async function fetchInline(url, options) {
    let data = null;

    if (isBrowser) {
        const defaultsOptions = {
            method: "GET",
        };

        const fetchOptions = merge(defaultsOptions, options);

        try {
            const response = await fetch(url, fetchOptions);

            data = await response.json();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    return data;
}

export async function fetchData(
    searchEndpoint,
    termsEndpoint,
    filters,
    signal,
) {
    const DATA_URL = `${process.env.GATSBY_ADMIN_SITE_URL}${searchEndpoint}`;
    const FILTERS_URL = `${process.env.GATSBY_ADMIN_SITE_URL}${termsEndpoint}`;

    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(filters),
        signal,
    };

    const dataPromise = fetchInline(DATA_URL, options);
    const termsPromise = fetchInline(FILTERS_URL, options);

    const [data, termData] = await Promise.all([dataPromise, termsPromise]);

    return { data, termData };
}
