import styled from "@emotion/styled";
import React from "react";

import transition from "~/utilities/transition";

import {
    h1,
    h2,
    h3,
    h4,
    h5,
    ul,
    userContentUl,
} from "~/styles/typographyStyles";

const Div = styled.div`
    h1 {
        ${h1};
    }

    h2 {
        ${h2};
    }

    h3 {
        ${h3};
        font-weight: 600;
    }

    h4 {
        ${h4};
        font-weight: 600;
    }

    h5 {
        ${h5};
    }

    a:not(.inlineIconLink) {
        ${transition({}, "color")};
        color: ${({ theme }) => theme.colors.typography.links};
        text-decoration: underline;

        &:hover,
        &:focus {
            color: ${({ theme }) => theme.colors.typography.linksHover};
        }
    }

    ul:not(.unstyled-list) {
        ${({ isKCArticle }) => (isKCArticle ? ul : userContentUl)};
    }

    ol {
        list-style: decimal;
        margin-bottom: ${({ theme }) => theme.layout.paragraphSpacing};
        padding-left: 1em;

        a {
            word-break: break-word;
        }
    }

    blockquote {
        margin-bottom: 40px;
        color: ${({ theme }) => theme.palette.teal};

        p {
            ${h2};
            margin-bottom: 0;
        }
    }

    hr {
        display: block;
        border: 0;
        border-top: ${({ theme }) => `1px solid ${theme.palette.borderGray}`};
        margin-top: ${({ theme }) => theme.layout.doubleGutter};
        margin-bottom: ${({ theme }) => theme.layout.doubleGutter};
    }
`;

const UserContent = ({ children, className, lang, isKCArticle }) => (
    <Div {...{ className, lang, isKCArticle }}>{children}</Div>
);

export default UserContent;
