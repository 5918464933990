import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";

import { rotateCentered } from "~/styles/keyframes";

import FaIcon from "~/components/FaIcon";
import ErrorBoundary from "~/components/ErrorBoundary";
import FormSuccess from "./FormSuccess";
import HtmlForm from "./HtmlForm";

import On24Form from "../utils/On24Form";

import { usePardotUserContext } from "~/context/PardotUserContext";

const FormWrapper = styled.div`
    position: relative;

    &:after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        opacity: ${({ isFormLoading }) => (isFormLoading ? 1 : 0)};
        visibility: ${({ isFormLoading }) =>
            isFormLoading ? `visible` : `hidden`};
    }
`;

const LoadingIconWrapper = styled.div`
    display: ${({ isFormLoading }) => (isFormLoading ? `block` : `none`)};
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: ${({ theme }) => theme.zIndex.smallest};
    font-size: 6rem;
`;

const LoadingIcon = styled(FaIcon)`
    animation: ${rotateCentered} 1s linear infinite normal forwards;
    color: ${({ theme }) => theme.palette.purple};
`;

const FormContainer = (props) => {
    const { isLoading: isLoadingPardotUser } = usePardotUserContext();
    const formWrapperRef = useRef(null);
    const [isFormLoading, setIsFormLoading] = useState(isLoadingPardotUser);

    const { success, setSuccess } = props;

    useEffect(() => {
        setIsFormLoading(isLoadingPardotUser);
    }, [isLoadingPardotUser]);

    const on24Props = On24Form.parseOn24Props({
        isOn24Form: props.isOn24Form,
        on24EventId: props.on24EventId,
        on24EventKey: props.on24EventKey,
        on24EventDate: props.on24EventDate,
    });

    const startLoading = () => {
        setIsFormLoading(true);
    };

    const stopLoading = () => {
        setIsFormLoading(false);
    };

    const handleSuccessForView = () => {
        stopLoading();
        setSuccess(true);
    };

    const handleModalScroll = (formFieldRef) => {
        if (formWrapperRef.current) {
            const modal = formWrapperRef.current.closest(
                ".ReactModal__Content",
            );
            if (modal) {
                const MARGIN = 70;
                const formFieldTop =
                    formFieldRef.current.getBoundingClientRect().top;
                modal.scrollTop =
                    modal.getBoundingClientRect().top + formFieldTop - MARGIN;
            }
        }
    };

    return (
        <FormWrapper ref={formWrapperRef} {...{ isFormLoading }}>
            <LoadingIconWrapper {...{ isFormLoading }}>
                <LoadingIcon name="spinner" />
            </LoadingIconWrapper>

            {success || on24Props?.storageData ? (
                <FormSuccess
                    formInformation={props.formInformation}
                    on24Props={on24Props}
                />
            ) : (
                <ErrorBoundary>
                    <HtmlForm
                        {...{
                            ...props,
                            on24Props,
                            handleSuccessForView,
                            stopLoading,
                            startLoading,
                            handleModalScroll,
                        }}
                    />
                </ErrorBoundary>
            )}
        </FormWrapper>
    );
};

export default FormContainer;
