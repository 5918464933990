import { useStaticQuery, graphql } from "gatsby";
import { find, has, get, keys } from "lodash";

import useUrlManager from "~/hooks/useUrlManager";
import { usePardotUserContext } from "~/context/PardotUserContext";

import {
    formatDataForSubmission,
    submitFormData,
    RECAPTCHA_LABEL,
    FormSubmissionException,
    handleFormException,
    updateMetaData,
} from "~/utilities/formHelpers";

import On24Form from "../utils/On24Form";

const useFormSubmission = ({
    runOnSubmitActions,
    runBeforeSubmitActions,
    runOnSubmit,
    on24Props,
    beforeSubmit,
    formInformation,
    formId,
    sendJSON,
    presetValues,
    reset,
    handleSuccessForView,
    stopLoading,
    startLoading,
    setFormPresetErrors,
    executeRecaptcha,
}) => {
    const { updateUserEmail } = usePardotUserContext();
    const { getQueryParams } = useUrlManager();

    const {
        wp: {
            optionsPage: { globalOptions },
        },
    } = useStaticQuery(graphql`
        query StaticFormQuery {
            wp {
                optionsPage {
                    globalOptions {
                        gravityForms
                        recaptchaUpdateMeta
                        on24WebinarEndpoint
                    }
                }
            }
        }
    `);

    const gravityFormsEndpoint = globalOptions?.gravityForms || "";
    const recaptchaUpdateMeta = globalOptions?.recaptchaUpdateMeta || "";
    const on24WebinarEndpoint = globalOptions?.on24WebinarEndpoint || "";

    const addRecaptchaValues = async (data) => {
        const emailVerificationToken = getQueryParams("vToken");

        if (emailVerificationToken) {
            data["vToken"] = emailVerificationToken;
        }

        if (runOnSubmitActions) {
            data["runOnSubmitActions"] = runOnSubmitActions;
        }

        if (on24Props) {
            data["on24Props"] = on24Props;
        }

        let formSubmissionData = data;

        if (executeRecaptcha) {
            const action = formInformation.title.replace(/[^A-Za-z]/g, "");
            const token = await executeRecaptcha(action);
            formSubmissionData = {
                ...data,
                [RECAPTCHA_LABEL]: token,
            };
        }

        return { formSubmissionData, data };
    };

    const formatDataForPardot = async (props) => {
        const { formSubmissionData } = props;

        let formattedData = formatDataForSubmission(
            formSubmissionData,
            formInformation,
        );

        if (beforeSubmit) {
            formattedData = await beforeSubmit(formattedData, formInformation);
        }

        return { ...props, formattedData };
    };

    const standardFormSubmission = async (props) => {
        const { formattedData, data } = props;

        const formSubmission = await submitFormData(
            formId,
            formattedData,
            sendJSON,
            gravityFormsEndpoint,
        );

        const dataKeys = keys(data);

        const emailField = find(dataKeys, (key) =>
            key.split("-").includes("email"),
        );

        const userEmail = get(data, emailField, false);

        return { formSubmission, userEmail, ...props };
    };

    const saveMetaData = async (props) => {
        const { formSubmission, data } = props;

        const code = formSubmission?.code;
        const postId = formSubmission?.data?.postId;

        if (
            code === "recaptcha_error" &&
            has(runBeforeSubmitActions, "createPDF") &&
            postId
        ) {
            const response = await updateMetaData(
                runBeforeSubmitActions,
                postId,
                recaptchaUpdateMeta,
            );

            if (!response?.wasUpdated) {
                console.error(
                    "Was unable to save PDF information.  PDF URL will not be emailed.",
                );
            }
        }

        if (code !== "success") {
            const formData = { ...data, formId };
            throw new FormSubmissionException(formSubmission, formData);
        }

        return props;
    };

    const on24FormSubmission = async ({ formattedData, userEmail }) => {
        if (on24Props) {
            const on24Form = new On24Form({
                on24WebinarEndpoint,
                on24Props,
                formInformation,
            });

            const response = await on24Form.handleOn24Request(
                formattedData,
                userEmail,
            );

            if (response?.code === "on24_error") {
                throw new FormSubmissionException(response);
            } else {
                return response;
            }
        } else {
            return { userEmail };
        }
    };

    const handleSuccess = ({ userEmail, on24RunOnSubmit }) => {
        if (userEmail) {
            updateUserEmail(userEmail);
        }

        if (on24RunOnSubmit) {
            on24RunOnSubmit();
        }

        if (runOnSubmit) {
            runOnSubmit();
        }

        reset(presetValues);

        handleSuccessForView();
    };

    const handleError = (exception) => {
        stopLoading();
        const error = handleFormException(exception, formInformation);
        setFormPresetErrors(error);
    };

    const handleFormSubmission = (data) => {
        startLoading();
        addRecaptchaValues(data)
            .then(formatDataForPardot)
            .then(standardFormSubmission)
            .then(saveMetaData)
            .then(on24FormSubmission)
            .then(handleSuccess)
            .catch(handleError);
    };

    return {
        handleFormSubmission,
    };
};

export default useFormSubmission;
