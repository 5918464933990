import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const screenReaderText = css`
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
`;

export const ScreenReaderText = styled.span`
    ${screenReaderText};
`;

export const SrOnlyLegend = styled.legend`
    ${screenReaderText}
`;

export const SrOnlyLabel = styled.label`
    ${screenReaderText}
`;
