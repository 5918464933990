import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { filter, isEmpty } from "lodash";

import Skeleton from "~/components/loaders/Skeleton";

import { formatCookieTypes, initCookies } from "~/utilities/cookieHelpers";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "~/utilities/localStorageHelpers";

const CookieNotice = lazy(() => import("~/components/CookieNotice"));

const CookieBlock = ({
    openFromButton = false,
    closeModalProp = null,
    inline = false,
}) => {
    const {
        wp: { gdprOptionsPage },
    } = useStaticQuery(graphql`
        query StaticCookieBlockQuery {
            wp {
                gdprOptionsPage {
                    cookieNotice {
                        cookieConsentModal {
                            title
                            copy
                            includeCookieList
                            cookieTypes {
                                title
                                copy
                                optional
                                defaultDisabled
                                eventAction
                                expiration
                                cookies {
                                    name
                                    duration
                                    description
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const [showCookieNotice, setShowCookieNotice] = useState(false);

    const cookieConsentModal =
        gdprOptionsPage?.cookieNotice?.cookieConsentModal || {};
    const { cookieTypes } = cookieConsentModal;

    const formattedCookieTypes = useMemo(() => {
        return formatCookieTypes(cookieTypes);
    }, [cookieTypes]);

    useEffect(() => {
        initCookies(formattedCookieTypes);
    }, [formattedCookieTypes]);

    useEffect(() => {
        const noCookieConsent =
            getLocalStorageItem("cookiesAccepted") !== "true";

        const existingCookieTypes = filter(
            formattedCookieTypes,
            (cookieType) => cookieType.cookieExists,
        );

        // If any cookies have been deleted or expired, update local storage to show banner
        const cookieIsInvalid =
            existingCookieTypes.length < formattedCookieTypes.length;

        if (noCookieConsent || cookieIsInvalid) {
            setLocalStorageItem("cookiesAccepted", "false");
            setShowCookieNotice(true);
        }
    }, [formattedCookieTypes]);

    useEffect(() => {
        if (showCookieNotice !== openFromButton && inline) {
            setShowCookieNotice(openFromButton);
        }
    }, [openFromButton, showCookieNotice, inline]);

    return !isEmpty(cookieConsentModal) && showCookieNotice ? (
        <Suspense fallback={<Skeleton variant="block" />}>
            <CookieNotice
                {...{
                    formattedCookieTypes,
                    setShowCookieNotice,
                    cookieConsentModal,
                    openFromButton,
                    closeModalProp,
                    inline,
                }}
            />
        </Suspense>
    ) : null;
};

CookieBlock.propTypes = {
    openFromButton: PropTypes.bool,
    closeModalProp: PropTypes.func,
    inline: PropTypes.bool,
};

export default CookieBlock;
