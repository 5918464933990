import { navigate } from "gatsby";
import queryString from "query-string";
import { useCallback } from "react";

import { useLocationContext } from "~/context/LocationContext";

const useUrlManager = () => {
    const { location } = useLocationContext();

    const getUpdateUrlFunc = useCallback((globalState = {}) => {
        return (url, localState) =>
            navigate(url, { state: localState ?? globalState });
    }, []);

    const getQueryParams = useCallback(
        (paramName) => {
            const queryParams = queryString.parse(location.search);

            if (paramName) {
                return queryParams[paramName]
                    ? queryParams[paramName].replace("/", "")
                    : "";
            }

            return queryParams;
        },
        [location.search],
    );

    return { location, getQueryParams, getUpdateUrlFunc };
};

export default useUrlManager;
