const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const days = {
    0: {
        short: "Sun",
        long: "Sunday",
    },
    1: {
        short: "Mon",
        long: "Monday",
    },
    2: {
        short: "Tue",
        long: "Tuesday",
    },
    3: {
        short: "Wed",
        long: "Wednesday",
    },
    4: {
        short: "Thu",
        long: "Thursday",
    },
    5: {
        short: "Friday",
        long: "Fri",
    },
    6: {
        short: "Sat",
        long: "Saturday",
    },
};

const addLeadingZero = (num) => {
    const str = num.toString();

    if (str.length === 1) {
        return `0${str}`;
    }

    return str;
};

export const formatArticleDate = (articleDate) => {
    return new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
    }).format(new Date(articleDate));
};

export const formatOn24EventDate = (date) => {
    const eventDate = new Date(date);
    return new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "America/New_York",
        hour12: true,
    }).format(eventDate);
};

export const getCurrentDate = () => {
    const d = new Date();
    let month = (d.getMonth() + 1).toString();

    if (month.length < 2) {
        month = `0${month}`;
    }

    let day = d.getDate().toString();

    if (day.length < 2) {
        day = `0${day}`;
    }

    return `${d.getFullYear()}-${month}-${day}`;
};

export const dateIsPast = (date) => {
    if (!date) return false;

    const currDate = Date.now();
    const targetDate = Date.parse(date);

    return targetDate <= currDate;
};

export const datesAreOnSameDay = (first, second) => {
    return (
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()
    );
};

export const monthsAreTheSame = (first, second) => {
    return (
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth()
    );
};

export const yearsAreTheSame = (first, second) => {
    return first.getFullYear() === second.getFullYear();
};

export const getDateOnly = (date) => {
    return date.setHours(0, 0, 0, 0);
};

export const formatEventDate = (startDate, endDate) => {
    if (!startDate || !endDate) return "";

    const start = new Date(Date.parse(startDate));
    const end = new Date(Date.parse(endDate));

    if (!endDate || start > end) {
        return `${
            months[start.getMonth()]
        } ${start.getDate()}, ${start.getFullYear()}`;
    }

    if (datesAreOnSameDay(start, end)) {
        return `${days[start.getDay()].long}, ${
            months[start.getMonth()]
        } ${start.getDate()}, ${start.getFullYear()}`;
    }

    if (monthsAreTheSame(start, end)) {
        return `${
            months[start.getMonth()]
        } ${start.getDate()} - ${end.getDate()}, ${start.getFullYear()}`;
    }

    if (yearsAreTheSame(start, end)) {
        return `${months[start.getMonth()]} ${start.getDate()} - ${
            months[end.getMonth()]
        } ${end.getDate()}, ${end.getFullYear()}`;
    }

    return `${
        months[start.getMonth()]
    } ${start.getDate()}, ${start.getFullYear()} - ${
        months[end.getMonth()]
    } ${end.getDate()}, ${end.getFullYear()}`;
};

export const getUTCFormat = (datetime) => {
    const year = datetime.getUTCFullYear();
    const month = addLeadingZero(datetime.getUTCMonth() + 1);
    const date = addLeadingZero(datetime.getUTCDate());
    const hour = addLeadingZero(datetime.getUTCHours());
    const minutes = addLeadingZero(datetime.getUTCMinutes());
    const seconds = addLeadingZero(datetime.getUTCSeconds());
    return `${year}-${month}-${date}T${hour}:${minutes}:${seconds}Z`;
};
