import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import MathComponent from "react-mathjax-preview";

const StyledMath = styled(MathComponent)`
    .react-mathjax-preview-result {
        display: inline;
    }
`;

const InlineMathjax = ({ children }) => (
    <StyledMath math={String.raw`${children}`} />
);

InlineMathjax.propTypes = {
    children: PropTypes.string.isRequired,
};

export default InlineMathjax;
