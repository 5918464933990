import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import FaIcon from "~/components/FaIcon";

import { ScreenReaderText } from "~/styles/screenReaderText";

import transition from "~/utilities/transition";

const Button = styled.button`
    ${transition({}, "color")};
    color: ${({ theme }) => theme.colors.typography.links};

    &:hover,
    &:focus {
        color: ${({ theme }) => theme.colors.typography.linksHover};
    }
`;

const IconOnlyButton = ({
    icon,
    iconLibrary = "",
    ariaLabel = "",
    screenReaderText = "",
    type = "button",
    onClick = () => {},
    tagRef = null,
    onKeyUp = () => {},
    ...props
}) => {
    const buttonProps = {
        onClick,
        type,
        ref: tagRef,
        onKeyDown: onKeyUp,
        ...props,
    };

    if (ariaLabel) {
        buttonProps["aria-label"] = ariaLabel;
    }

    return (
        <Button {...buttonProps}>
            <FaIcon name={icon} {...{ iconLibrary }} />
            {screenReaderText && (
                <ScreenReaderText>{screenReaderText}</ScreenReaderText>
            )}
        </Button>
    );
};

IconOnlyButton.propTypes = {
    icon: PropTypes.string.isRequired,
    iconLibrary: PropTypes.string,
    screenReaderText: PropTypes.string,
    ariaLabel: PropTypes.string,
    tagRef: PropTypes.object,
    onKeyUp: PropTypes.func,
};

export default IconOnlyButton;
