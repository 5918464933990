import { ThemeProvider } from "@emotion/react";
import React from "react";

import theme from "~/theme";

import { FormProvider } from "~/context/FormContext";
import { PardotUserProvider } from "~/context/PardotUserContext";
import { StickyProvider } from "~/context/StickyContext";
import { LocationProvider } from "~/context/LocationContext";
import { CartProvider } from "~/context/CartContext";
import { TranslationProvider } from "~/context/TranslationContext";

export function onRenderBody({ setHtmlAttributes }) {
    setHtmlAttributes({ lang: "en-us" });
}

const GlobalContext = ({ element, props }) => {
    return (
        <LocationProvider pageProps={props}>
            <TranslationProvider pageProps={props}>
                <PardotUserProvider>
                    <FormProvider>
                        <StickyProvider>
                            <CartProvider>
                                <ThemeProvider {...{ theme }}>
                                    {element}
                                </ThemeProvider>
                            </CartProvider>
                        </StickyProvider>
                    </FormProvider>
                </PardotUserProvider>
            </TranslationProvider>
        </LocationProvider>
    );
};

export const wrapRootElement = ({ element }) => {
    return <GlobalContext element={element} />;
};

export const wrapPageElement = ({ element, props }) => {
    return <GlobalContext element={element} props={props} />;
};
