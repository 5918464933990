import styled from "@emotion/styled";

const ContentContainer = styled.div`
    width: 100%;
    padding-left: ${({ theme }) => `calc(${theme.layout.gutter} / 2)`};
    padding-right: ${({ theme }) => `calc(${theme.layout.gutter} / 2)`};
    margin-left: auto;
    margin-right: auto;

    @media (min-width: ${({ theme }) => theme.breakpoints.mobileLandscape}) {
        max-width: ${({ theme }) =>
            `calc(${theme.layout.maxContentWidth} + 2 * ${theme.layout.gutter})`};
        padding-left: ${({ theme }) => theme.layout.gutter};
        padding-right: ${({ theme }) => theme.layout.gutter};
    }

    & &,
    .ReactModal__Content & {
        padding-left: 0;
        padding-right: 0;

        @media (min-width: ${({ theme }) =>
                theme.breakpoints.mobileLandscape}) {
            padding-left: 0;
            padding-right: 0;
        }
    }
`;

export default ContentContainer;
