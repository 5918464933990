import styled from "@emotion/styled";
import React from "react";

import SuccessMessage from "~/components/FormExtras/SuccessMessage";
import ButtonLink from "~/components/links/ButtonLink";

import { h2 } from "~/styles/typographyStyles";

const On24Success = styled.section``;

const Heading = styled.h2`
    ${h2};
    color: ${({ theme }) => theme.palette.purple};
`;

const Header = styled.header`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-top: 4rem;
`;

const FormSuccess = ({ on24Props, formInformation }) => {
    if (on24Props?.storageData) {
        const { on24Link, userEmail, eventDate } = on24Props.storageData;
        return (
            <On24Success>
                <Header>
                    <Heading>Wolfspeed Webinar</Heading>
                    {eventDate ? (
                        <time dateTime={eventDate.utcDate}>
                            {eventDate.formattedDate}
                        </time>
                    ) : null}
                </Header>
                <Body>
                    <h3>Thank you for registering for Wolfspeed Webinar.</h3>
                    <ButtonLink to={on24Link}>Join the Event</ButtonLink>
                    <span>
                        Email: <a href={`mailto:${userEmail}`}>{userEmail}</a>
                    </span>
                    <span>Thank you and enjoy the Event!</span>
                </Body>
            </On24Success>
        );
    }

    return (
        <SuccessMessage
            formInformation={formInformation}
            successfulSubmission={true}
        />
    );
};

export default FormSuccess;
