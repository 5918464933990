import React, {
    useContext,
    createContext,
    useCallback,
    useEffect,
    useReducer,
} from "react";
import { map, filter, find, reduce, forEach, isEmpty } from "lodash";

import {
    getLocalStorageWithExpiry,
    setLocalStorageWithExpiry,
    removeLocalStorageItem,
} from "~/utilities/localStorageHelpers";

import trackCustomEvent from "~/utilities/trackCustomEvent";

const CartContext = createContext();

const fortyEightHours = 60 * 60 * 24 * 2 * 1000;
const STORAGE_KEY = "SHOPPING_CART";

const cartMetaData = {
    arrow: { key: "arrow", label: "Arrow" },
    orderSample: { key: "orderSample", label: "Order Sample" },
};

const handleCartTracking = (clickType, product, key) => {
    const label = cartMetaData[key]?.label || "";

    const eventOptions = {
        action: `${clickType.toLowerCase()}`,
        label: `${label} Shopping Cart`,
        value: { item_id: product?.sku, quantity: product?.quantity },
        additionalTracking: {
            ecommerce: {
                items: [
                    {
                        item_id: product?.sku,
                        quantity: product?.quantity,
                    },
                ],
            },
        },
    };

    trackCustomEvent("gaEvent", eventOptions);
};

const handleBulkCartTracking = (clickType, cart, key) => {
    const label = cartMetaData[key]?.label || "";
    const parsedCart = map(cart, ({ sku, quantity }) => {
        return { item_id: sku, quantity };
    });

    const eventOptions = {
        action: `${clickType.toLowerCase()}`,
        label: `${label} Shopping Cart`,
        value: parsedCart,
        additionalTracking: {
            ecommerce: {
                items: parsedCart,
            },
        },
    };

    trackCustomEvent("gaEvent", eventOptions);
};

const handleBulkRemoveCartTracking = (cart) => {
    for (const key in cart) {
        forEach(cart[key], (product) => {
            handleCartTracking("REMOVE_FROM_CART", product, key);
        });
    }
};

const isCartEmpty = (cart) => {
    return reduce(
        cart,
        (isEmpty, shoppingCart) => {
            if (shoppingCart.length > 0 && isEmpty) {
                return false;
            }
            return isEmpty;
        },
        true,
    );
};

const initialState = {
    cart: { [cartMetaData.arrow.key]: [], [cartMetaData.orderSample.key]: [] },
    isInitialLoad: false,
    userStartedCheckout: false,
    loading: true,
};

const stateReducer = (state, action) => {
    switch (action.type) {
        case "BULK_ADD_TO_CART":
            return {
                ...state,
                cart: action.cart,
                loading: false,
            };
        case "ADD_TO_CART":
            return {
                ...state,
                cart: {
                    ...state.cart,
                    [action.key]: [action.product, ...state.cart[action.key]],
                },
            };
        case "REMOVE_FROM_CART":
            const filteredCart = filter(
                state.cart[action.key],
                (product) => product.sku !== action.sku,
            );
            return {
                ...state,
                cart: {
                    ...state.cart,
                    [action.key]: filteredCart,
                },
            };
        case "SET_QUANTITY":
            const updatedCart = map(state.cart[action.key], (product) => {
                if (product.sku === action.sku) {
                    const quantity = Number.parseInt(action.quantity, 10);
                    return {
                        ...product,
                        quantity,
                        [action.quantityKey]: quantity,
                    };
                }
                return product;
            });
            return {
                ...state,
                cart: {
                    ...state.cart,
                    [action.key]: updatedCart,
                },
            };
        case "CLEAR_CART":
            return {
                ...state,
                cart: initialState.cart,
                isInitialLoad: false,
                userStartedCheckout: false,
            };
        case "SET_STARTED_CHECKOUT":
            return {
                ...state,
                userStartedCheckout: true,
            };
        case "SET_IS_INITIAL_LOAD":
            return {
                ...state,
                isInitialLoad: action.isInitialLoad,
            };
        default:
            return state;
    }
};

const reducerWithTracking = (oldState, action) => {
    if (action.type === "ADD_TO_CART") {
        handleCartTracking("ADD_TO_CART", action.product, action.key);
        return;
    }

    if (action.type === "REMOVE_FROM_CART") {
        const cart = oldState.cart[action.key];
        const product = find(cart, { sku: action.sku }) || {};
        handleCartTracking("REMOVE_FROM_CART", product, action.key);
        return;
    }

    if (action.type === "CLEAR_CART") {
        for (const key in oldState.cart) {
            const cart = oldState.cart[key];

            if (!isEmpty(cart)) {
                handleBulkCartTracking("CLEAR_CART", cart, key);
            }
        }
        handleBulkRemoveCartTracking(oldState.cart);
        return;
    }
};

const reducerWithStorage = (newCart, action) => {
    if (action.type === "CLEAR_CART") {
        removeLocalStorageItem(STORAGE_KEY);
    } else {
        setLocalStorageWithExpiry(STORAGE_KEY, newCart, fortyEightHours);
    }
};

const reducer = (state, action) => {
    const newState = stateReducer(state, action);
    reducerWithTracking(state, action);
    reducerWithStorage(newState.cart, action);
    return newState;
};

export const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const { cart, isInitialLoad, userStartedCheckout, loading } = state;

    useEffect(() => {
        const savedCart =
            getLocalStorageWithExpiry(STORAGE_KEY) || initialState.cart;
        dispatch({ type: "BULK_ADD_TO_CART", cart: savedCart });
    }, []);

    useEffect(() => {
        if (isInitialLoad && !userStartedCheckout) {
            window.trackAbandonedCart = () => {
                for (const key in cart) {
                    if (!isEmpty(cart[key])) {
                        handleBulkCartTracking(
                            "ABANDONED_CART",
                            cart[key],
                            key,
                        );
                    }
                }
            };
        } else {
            window.trackAbandonedCart = null;
        }
    }, [isInitialLoad, userStartedCheckout, cart]);

    const addToCart = useCallback((product, key) => {
        dispatch({ type: "ADD_TO_CART", product, key });
    }, []);

    const removeFromCart = (sku, key) => {
        dispatch({ type: "REMOVE_FROM_CART", sku, key });
    };

    const setQuantity = (sku, quantity, quantityKey, key) => {
        dispatch({ type: "SET_QUANTITY", sku, quantity, quantityKey, key });
    };

    const clearCart = () => {
        dispatch({ type: "CLEAR_CART" });
    };

    const setInitialLoad = useCallback((isInitialLoad) => {
        dispatch({ type: "SET_IS_INITIAL_LOAD", isInitialLoad });
    }, []);

    const handleActionTracking = (clickType, key) => {
        if (clickType === "BEGIN_CHECKOUT" && !userStartedCheckout) {
            dispatch({ type: "SET_STARTED_CHECKOUT" });
        }

        handleBulkCartTracking(clickType, cart[key], key);
    };

    return (
        <CartContext.Provider
            value={{
                cart,
                loading,
                addToCart,
                removeFromCart,
                setQuantity,
                clearCart,
                isInitialLoad,
                setInitialLoad,
                handleActionTracking,
                cartIsEmpty: isCartEmpty(cart),
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export const useCartContext = () => {
    const context = useContext(CartContext);

    if (context === undefined) {
        throw new Error("useCartContext must be used within a CartProvider");
    }

    return context;
};
