import { some, has } from "lodash";
import { isBrowser } from "~/utilities/helpers";

const jsonParse = (value) => {
    try {
        return JSON.parse(value);
    } catch (error) {
        console.error(error.message);
        return null;
    }
};

const jsonStringify = (value) => {
    try {
        return JSON.stringify(value);
    } catch (error) {
        console.error(error.message);
        return "";
    }
};

function getLocalStorageItem(storageKey) {
    if (!isBrowser) return;

    return localStorage.getItem(storageKey);
}

function setLocalStorageItem(storageKey, value) {
    if (!isBrowser) return;

    localStorage.setItem(storageKey, value);
}

function getLocalStorageSerialize(
    storageKey,
    valueToReturnIfNotPresent = null,
) {
    const item = getLocalStorageItem(storageKey);

    return item ? jsonParse(item) : valueToReturnIfNotPresent;
}

function setLocalStorageSerialize(storageKey, value) {
    setLocalStorageItem(storageKey, jsonStringify(value));
}

function addLocalStorageListItem(storageKey, newItem) {
    if (isBrowser) {
        const currentItems = getLocalStorageSerialize(storageKey) ?? [];

        if (
            newItem &&
            has(newItem, `id`) &&
            !some(currentItems, { id: newItem.id })
        ) {
            const newItems = currentItems.concat(newItem);

            setLocalStorageSerialize(storageKey, newItems);
        }

        return currentItems;
    }
}

function setLocalStorageWithExpiry(key, value, ttl) {
    if (!isBrowser) {
        return;
    }

    const now = new Date();

    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    };

    localStorage.setItem(key, jsonStringify(item));
}

function getLocalStorageWithExpiry(key) {
    if (!isBrowser) {
        return;
    }

    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
        return null;
    }

    const item = jsonParse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}

function removeLocalStorageItem(key) {
    if (!isBrowser) {
        return;
    }

    localStorage.removeItem(key);
}

export {
    addLocalStorageListItem,
    getLocalStorageSerialize,
    setLocalStorageSerialize,
    getLocalStorageItem,
    setLocalStorageItem,
    setLocalStorageWithExpiry,
    getLocalStorageWithExpiry,
    removeLocalStorageItem,
};
