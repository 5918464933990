import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { useState } from "react";

import TextButton from "~/components/buttons/TextButton";
import IconButton from "~/components/buttons/IconButton";

import ErrorBoundary from "~/components/ErrorBoundary";

import Form from "~/queryBlocks/Form";

import { h2 } from "~/styles/typographyStyles";

const Title = styled.h2`
    ${h2};
`;

const InlineForm = ({
    form,
    children,
    title = "",
    icon = null,
    iconLibrary = null,
    beforeSubmit = null,
    runOnSubmit,
    runBeforeSubmitActions,
    runOnSubmitActions,
    formPresetValues,
    sendJSON = true,
    ...rest
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <ErrorBoundary>
            {title ? <Title>{title}</Title> : null}
            <Form
                formId={Number.parseInt(form, 10)}
                modalForm={true}
                isModalOpenProp={modalIsOpen}
                closeModalProp={() => setModalIsOpen(false)}
                useChildButton={true}
                {...{
                    beforeSubmit,
                    sendJSON,
                    runOnSubmit,
                    runOnSubmitActions,
                    formPresetValues,
                    runBeforeSubmitActions,
                }}
            >
                {icon ? (
                    <IconButton
                        onClick={() => setModalIsOpen(true)}
                        {...{ icon, ...rest }}
                        iconLibrary={iconLibrary ?? "fas"}
                    >
                        {children}
                    </IconButton>
                ) : (
                    <TextButton onClick={() => setModalIsOpen(true)}>
                        {children}
                    </TextButton>
                )}
            </Form>
        </ErrorBoundary>
    );
};

InlineForm.propTypes = {
    form: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    title: PropTypes.string,
    beforeSubmit: PropTypes.func,
    icon: PropTypes.string,
    iconLibrary: PropTypes.string,
    sendJSON: PropTypes.bool,
};

export default InlineForm;
