import { useCallback, useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { find } from "lodash";

const useAllFormData = () => {
    const { allGfForm } = useStaticQuery(graphql`
        query {
            allGfForm {
                nodes {
                    ...GravityFormComponent
                    formFields {
                        pardotField
                        on24Field
                        autocompleteAttribute
                    }
                }
            }
        }
    `);

    const allForms = useMemo(() => allGfForm.nodes ?? [], [allGfForm.nodes]);

    const getFormById = useCallback(
        (formId) => {
            return find(allForms, { formId });
        },
        [allForms],
    );

    return { allGfForm, getFormById };
};

export default useAllFormData;
