import { ErrorMessage } from "@hookform/error-message";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import React from "react";
import { isEmpty, replace, map } from "lodash";

import { getErrorMessage, ariaAlert } from "~/utilities/formHelpers";

import FaIcon from "../FaIcon";

import { Error, Label } from "./Input";

const SelectWrapper = styled.div`
    position: relative;
`;

export const selectStyles = ({
    theme,
    disabled,
    error,
    backgroundColor,
}) => css`
    width: 100%;
    padding: 10px;
    appearance: none;
    border: 2px solid ${theme.palette.grayOne};
    background-color: ${disabled
        ? theme.palette.grayFour
        : backgroundColor === "white"
          ? theme.palette.white
          : theme.palette.grayThree};
    cursor: ${disabled ? `not-allowed` : null};
    overflow: hidden;

    &:focus,
    &:focus-visible {
        border: ${error
            ? `2px solid ${theme.palette.red}`
            : `2px solid ${theme.palette.black}`};
        outline: none;
    }
`;

const StyledSelect = styled.select`
    ${selectStyles};
`;

const Icon = styled(FaIcon)`
    position: absolute;
    top: 30%;
    right: 20px;
    pointer-events: none;
`;

const Select = ({
    label,
    name,
    options,
    register,
    errors = null,
    required = false,
    disabled = false,
    backgroundColor = "gray",
    autocompleteAttribute = "on",
    errorMessage = "",
}) => (
    <>
        <Label htmlFor={name}>
            {label}
            {required ? <span>*</span> : ""}
        </Label>
        <SelectWrapper>
            <Icon name="chevron-down" />
            <StyledSelect
                {...{
                    name,
                    disabled,
                    backgroundColor,
                }}
                {...register(name, {
                    required: required
                        ? getErrorMessage(errorMessage, label)
                        : false,
                })}
                autoComplete={autocompleteAttribute}
                error={errors[name]}
                id={name}
                aria-required={required}
                aria-label={ariaAlert(label, errors[name])}
            >
                {!isEmpty(options)
                    ? map(options, ({ text, value }, index) => {
                          return (
                              <option key={`${text}=${index}`} {...{ value }}>
                                  {replace(text, "&amp;", "&")}
                              </option>
                          );
                      })
                    : null}
            </StyledSelect>
        </SelectWrapper>

        <ErrorMessage
            {...{ errors, name }}
            render={({ message }) => <Error>{message}</Error>}
        />
    </>
);

Select.propTypes = {
    label: PropTypes.string.isRequired,
    errors: PropTypes.shape({}),
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            isSelected: PropTypes.bool.isRequired,
        }).isRequired,
    ).isRequired,
    backgroundColor: PropTypes.oneOf(["white", "gray"]),
    autocompleteAttribute: PropTypes.string,
    errorMessage: PropTypes.string,
};

export default Select;
