import React, { useRef, createRef } from "react";
import { find, has, map, isEmpty } from "lodash";

import { useDebouncedCallback } from "use-debounce";

import FormField from "~/components/FormElements/FormField";
import ErrorSummary from "~/components/FormExtras/ErrorSummary";

import { addErrorSummary } from "~/utilities/formHelpers";
import { scrollToElement } from "~/utilities/helpers";

const FormFields = ({
    formFields,
    setFocus,
    modalForm,
    register,
    errors,
    uniqueFormId,
    control,
    getValues,
    handleModalScroll,
}) => {
    const formFieldRefs = useRef([]);

    if (!isEmpty(formFields)) {
        formFieldRefs.current = map(formFields, () => createRef());
    }

    const setFocusWithDelay = useDebouncedCallback((fieldName) => {
        setFocus(fieldName);
    }, 500);

    const handleSetFocus = (fieldName) => {
        if (fieldName !== "non_field_error") {
            const formFieldRef = find(formFieldRefs.current, (fieldRef) => {
                if (fieldRef.current) {
                    return fieldRef.current.querySelector(
                        `[name=${fieldName}]`,
                    );
                }
                return false;
            });
            if (formFieldRef) {
                if (modalForm) {
                    handleModalScroll(formFieldRef);
                } else {
                    scrollToElement(formFieldRef.current, -20);
                }
                setFocusWithDelay(fieldName);
            }
        }
    };

    return map(addErrorSummary(formFields), (field, index) => {
        if (has(field, "errorSummary")) {
            return (
                <ErrorSummary
                    setFocus={handleSetFocus}
                    errors={errors}
                    key="errorSummary"
                />
            );
        } else {
            return (
                <FormField
                    ref={formFieldRefs.current[index - 1]}
                    {...{
                        field,
                        register,
                        errors,
                        uniqueFormId,
                        control,
                        formFields,
                        getValues,
                        key: field.id,
                    }}
                />
            );
        }
    });
};

export default FormFields;
