import trackCustomEvent from "~/utilities/trackCustomEvent";

const VIDEO_JS_EVENT_NAMES = [
    "firstplay",
    "pause",
    "seek",
    "buffered",
    "first-quarter",
    "second-quarter",
    "third-quarter",
    "fourth-quarter",
    "performance",
];

function handleVideoJSTracking(data, category, action) {
    const events = Object.keys(data);
    events.forEach((event) => {
        trackCustomEvent("gaEvent", {
            category,
            action,
            label: event,
            value: data[event],
        });
    });
}

export const trackVideoJSEvents = (player, category) => {
    VIDEO_JS_EVENT_NAMES.forEach((eventName) => {
        player.on(`tracking:${eventName}`, (e, data) =>
            handleVideoJSTracking(data, category, eventName),
        );
    });
};

const handleReactPlayerEvents = (category, action, label, value) => {
    trackCustomEvent("gaEvent", {
        category,
        action,
        label,
        value,
    });
};

const handleReactPlayerOnProgress = (progress, category, action) => {
    const progressEvents = Object.keys(progress);
    progressEvents.forEach((event) => {
        trackCustomEvent("gaEvent", {
            category,
            action,
            label: event,
            value: progress[event],
        });
    });
};

export const trackReactPlayerEvents = (category) => {
    return {
        onStart: () =>
            handleReactPlayerEvents(
                category,
                "On Start",
                "Init",
                "Initial start",
            ),
        onPlay: () =>
            handleReactPlayerEvents(
                category,
                "On Play",
                "Play",
                "Play after pause or buffer",
            ),
        onProgress: (progress) =>
            handleReactPlayerOnProgress(progress, category, "On progress"),
        onDuration: (duration) =>
            handleReactPlayerEvents(
                category,
                "On Duration",
                "Duration",
                duration,
            ),
        onPause: () =>
            handleReactPlayerEvents(
                category,
                "On Pause",
                "Pause",
                "Video was paused",
            ),
        onBuffer: () =>
            handleReactPlayerEvents(
                category,
                "On Buffer",
                "Buffer",
                "Buffer started",
            ),
        onBufferEnd: () =>
            handleReactPlayerEvents(
                category,
                "On Buffer End",
                "Buffer",
                "Buffer ended",
            ),
        onSeek: (seconds) =>
            handleReactPlayerEvents(
                category,
                "On Seek",
                "Seek Seconds",
                seconds,
            ),
        onEnded: () =>
            handleReactPlayerEvents(
                category,
                "On Ended",
                "Ended",
                "Video ended",
            ),
        onError: () =>
            handleReactPlayerEvents(
                category,
                "On Error",
                "Error",
                "Error occurred",
            ),
    };
};
