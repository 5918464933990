import styled from "@emotion/styled";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import React from "react";

import BaseLink from "~/components/links/BaseLink";

import FaIcon from "~/components/FaIcon";

import transition from "~/utilities/transition";
import { link } from "~/styles/typographyStyles";

const getWrapperStyles = ({
    rightalign,
    margintop,
    paddingbottom,
    linkColor,
    theme,
}) => css`
    ${transition({}, "text-decoration, color")};
    display: inline-flex;
    padding-bottom: ${paddingbottom};
    margin-top: ${margintop};
    float: ${rightalign === "true" ? `right` : `none`};
    align-self: ${rightalign === "true" ? `flex-end` : `flex-start`};
    color: ${theme.iconLinkColors[linkColor].color};
`;

const DivWrapper = styled.div`
    ${getWrapperStyles};
`;

const LinkWrapper = styled(BaseLink)`
    ${getWrapperStyles};

    &:hover,
    &:focus {
        text-decoration: underline;
        color: ${({ theme, linkColor }) =>
            theme.iconLinkColors[linkColor].hover};
    }
`;

const LinkLabel = styled.span`
    ${link};
    line-height: 1.38;
`;

const Icon = styled(FaIcon)`
    ${link};
    margin-left: 5px;
    margin-top: 5px;
`;

const InlineIconLink = ({
    children,
    href = "",
    icon = "chevron-right",
    rightalign = "false",
    paddingbottom = "0",
    margintop = "0",
    color = "purple",
    newtab = "false",
    ...props
}) => {
    const Wrapper = href ? LinkWrapper : DivWrapper;

    const wrapperProps = {
        rightalign,
        margintop,
        paddingbottom,
        linkColor: color,
        className: "inlineIconLink",
        newTabOverride: newtab === "true" ? "newTab" : "default",
        ...props,
    };

    if (href) {
        wrapperProps.to = href;
    }

    return (
        <Wrapper {...wrapperProps}>
            <LinkLabel>{children}</LinkLabel>
            <Icon name={icon ?? "chevron-right"} />
        </Wrapper>
    );
};

InlineIconLink.propTypes = {
    children: PropTypes.string.isRequired,
    href: PropTypes.string,
    icon: PropTypes.string,
    rightalign: PropTypes.string,
    margintop: PropTypes.string,
    paddingbottom: PropTypes.string,
    color: PropTypes.string,
    newtab: PropTypes.string,
};

export default InlineIconLink;
