import { merge, cloneDeep, has } from "lodash";

import { upsertOption } from "~/utilities/reducer";
import { getLocalStorageSerialize } from "~/utilities/localStorageHelpers";
import {
    checkDocumentAttributes,
    getDocumentToProductSlug,
    getFormIdByProductLine,
    getProductLines,
    getDocumentTermsOfUse,
} from "~/utilities/helpers";
import { formatDownloadVideo } from "~/utilities/videoHelpers";

export const gatedDocumentState = {
    documentToProductSlug: null,
    formId: null,
    isGated: false,
    isModalOpen: false,
    isVideo: false,
    termsOfUseStorage: null,
    termsOfUseTerms: null,
    shouldUseTerms: false,
    videoUrl: null,
    modalWasClosed: false,
};

export function initGatedDocumentState(doc, gatedDocumentForms) {
    let termsOfUseTerms = null;
    let termsOfUseStorage = null;
    let videoUrl = null;
    let isEmbed = false;
    let poster = null;

    const documentToProductSlug = getDocumentToProductSlug(doc);
    const termsOfUseObject = getDocumentTermsOfUse(doc);
    const productLines = getProductLines(doc?.productLines ?? []);

    const gatedFormStorage = getLocalStorageSerialize("gatedFormStorage", []);
    const hasGatedField = checkDocumentAttributes(doc, `isGated`);
    const formWasNotCompleted = !gatedFormStorage.includes(
        `product-${documentToProductSlug}`,
    );

    const isGated = hasGatedField && formWasNotCompleted;
    const isVideo = checkDocumentAttributes(doc, `isVideo`);
    if (isVideo) {
        const videoAttrs = formatDownloadVideo(doc);
        if (videoAttrs) {
            videoUrl = videoAttrs.url;
            isEmbed = videoAttrs.isEmbed;
            poster = videoAttrs.poster;
        }
    }

    const shouldUseTerms =
        !isGated && has(termsOfUseObject, `terms`) && termsOfUseObject.terms;
    let formId = checkDocumentAttributes(doc, `overrideFormId`);
    if (!formId && productLines.length) {
        formId = getFormIdByProductLine(gatedDocumentForms, productLines[0]);
    }

    if (shouldUseTerms) {
        termsOfUseStorage = termsOfUseObject.documentStorage;
        termsOfUseTerms = termsOfUseObject.terms;
    }

    return merge({}, gatedDocumentState, {
        documentToProductSlug,
        formId,
        isGated,
        isVideo,
        isEmbed,
        termsOfUseStorage,
        termsOfUseTerms,
        shouldUseTerms,
        videoUrl,
        poster,
    });
}

export function gatedDocumentReducer(state, action) {
    let updatedState = cloneDeep(state);
    const { type } = action;
    switch (type) {
        case "OPEN_MODAL":
            updatedState = upsertOption(updatedState, {
                isModalOpen: true,
                modalWasClosed: false,
            });
            break;
        case "CLOSE_MODAL":
            updatedState = upsertOption(updatedState, {
                isModalOpen: false,
                modalWasClosed: true,
            });
            break;
        case "UPDATE_STATE":
            updatedState = upsertOption(updatedState, {
                ...action.newState,
            });
            break;
        default:
            console.dir(action);
            console.log(`Unkown Form Action ${action.type}`);
            break;
    }

    return updatedState;
}
