import PropTypes from "prop-types";
import React, { useState } from "react";

import BaseModal from "~/components/BaseModal";
import VideoEmbed from "~/components/VideoEmbed";

import IconButton from "~/components/buttons/IconButton";
import TextButton from "~/components/buttons/TextButton";

const InlineVideoButton = ({
    children,
    videourl,
    poster,
    aspectratio = "",
    noicon = false,
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <>
            {noicon ? (
                <TextButton onClick={() => setModalIsOpen(true)}>
                    {children}
                </TextButton>
            ) : (
                <IconButton
                    onClick={() => setModalIsOpen(true)}
                    icon="play-circle"
                    color="teal"
                >
                    {children}
                </IconButton>
            )}
            <BaseModal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
            >
                <VideoEmbed
                    url={videourl}
                    isEmbed={
                        videourl.includes("youtube") ||
                        videourl.includes("vimeo")
                    }
                    {...{ aspectratio, poster }}
                />
            </BaseModal>
        </>
    );
};

InlineVideoButton.propTypes = {
    children: PropTypes.string.isRequired,
    videourl: PropTypes.string.isRequired,
    poster: PropTypes.string,
    aspectratio: PropTypes.string,
    noicon: PropTypes.bool,
};

export default InlineVideoButton;
