import { css } from "@emotion/react";

import transition from "~/utilities/transition";

export const h1 = ({ theme }) => css`
    font-size: 4.4rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0.425em;

    @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        font-size: 5.4rem;
    }
`;

export const h2 = ({ theme }) => css`
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0.475em;

    @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        font-size: 4rem;
    }
`;

export const h3 = ({ theme }) => css`
    font-size: 2.7rem;
    line-height: 1.2;
    margin-bottom: 0.633em;

    @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        font-size: 3rem;
    }
`;

export const h4 = ({ theme }) => css`
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 0.85em;

    @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        font-size: 2rem;
    }
`;

export const h5 = ({ theme }) => css`
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0.85em;

    @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        font-size: 2rem;
    }
`;

export const link = ({ theme }) => css`
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.8;
    letter-spacing: -0.0075em;

    @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        font-size: 2rem;
    }
`;

export const caption = () => css`
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0;
`;

export const sectionHeading = ({ theme }) => css`
    font-size: 3.4rem;

    @media (min-width: ${theme.breakpoints.tabletPortrait}) {
        font-size: 3.8rem;
    }
`;

export const ul = ({ theme, isKCArticle, bulletColor }) => css`
    list-style: none;
    margin-bottom: ${theme.layout.paragraphSpacing};
    border-bottom: ${isKCArticle
        ? `none`
        : `1px solid ${theme.palette.borderGray}`};
    margin-left: ${isKCArticle ? `1em` : `0`};

    &.no-indent {
        margin-left: 0;
    }

    li {
        font-weight: 400;
        border-bottom: none;
        padding-bottom: 10px;
        padding-left: 1em;
        display: block;
        position: relative;

        &:not(:last-of-type) {
            margin-bottom: 10px;
        }

        &::before {
            content: "";
            height: 4px;
            width: 4px;
            background-color: ${bulletColor
                ? theme.palette[bulletColor]
                : theme.palette.black};
            margin: 8px 0 0 -20px;
            padding: 4px;
            border-radius: 50%;
            position: absolute;
            top: 6px;
            display: ${isKCArticle ? `inline-block` : `none`};
        }
    }

    &.no-vertical-space {
        li {
            padding-bottom: 0;

            &:not(:last-of-type) {
                margin-bottom: 0;
            }
        }
    }

    &.bullet-purple {
        li {
            &::before {
                background-color: ${theme.palette.purple};
            }
        }
    }

    &.bullet-teal {
        li {
            &::before {
                background-color: ${theme.palette.teal};
            }
        }
    }

    &.bullet-gray {
        li {
            &::before {
                background-color: ${theme.palette.grayOne};
            }
        }
    }

    a {
        ${transition({}, "color")};
        word-break: break-word;
        color: ${theme.colors.typography.links};

        &:hover,
        &:focus {
            color: ${theme.colors.typography.linksHover};
        }
    }
`;

export const userContentUl = ({ theme }) => css`
    margin-bottom: ${theme.layout.paragraphSpacing};
    margin-left: 1em;

    li {
        padding-left: 1em;
        position: relative;

        &:not(:last-of-type) {
            margin-bottom: 5px;
        }

        &::before {
            content: "•";
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    a {
        word-break: break-word;
    }

    &.no-indent {
        margin-left: 0;
    }
`;

export const ol = ({ theme }) => css`
    list-style: decimal;
    margin-bottom: ${theme.layout.paragraphSpacing};
    padding-left: 1em;

    a {
        ${transition({}, "color")};
        word-break: break-word;
        color: ${theme.colors.typography.links};

        &:hover,
        &:focus {
            color: ${theme.colors.typography.linksHover};
        }
    }
`;
