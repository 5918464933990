import { useStaticQuery, graphql } from "gatsby";
import React, {
    createContext,
    useContext,
    useEffect,
    useReducer,
    useCallback,
} from "react";
import { isArray } from "lodash";

import {
    pardotUserState,
    initPardotUser,
    pardotUserEmailReducer,
} from "~/reducers/pardot";

import { fetchInline } from "~/utilities/fetchHelper";
import { setLocalStorageSerialize } from "~/utilities/localStorageHelpers";

const PardotUserContext = createContext();

const fetchPardotUser = (pathname, options, dispatch) => {
    const prefillUrl = `${process.env.GATSBY_ADMIN_SITE_URL}${pathname}`;

    fetchInline(prefillUrl, options)
        .then((userData) => {
            dispatch({ type: "SET_PARDOT_USER_DATA", userData });
        })
        .catch((e) => {
            if (e instanceof DOMException && e.name === "AbortError") {
                return;
            }
            dispatch({ type: "STOP_LOADING" });
            console.dir(e);
            console.error("Issues loading pardot user");
        });
};

export const PardotUserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        pardotUserEmailReducer,
        pardotUserState,
        initPardotUser,
    );
    const { gatedStorage, isLoading, termsStorage, userEmail, userData } =
        state;

    const {
        wp: { optionsPage },
    } = useStaticQuery(graphql`
        query StaticPardotUserProviderQuery {
            wp {
                optionsPage {
                    globalOptions {
                        pardotUserPrefillUrl
                    }
                }
            }
        }
    `);

    const pardotUserPrefillUrl =
        optionsPage?.globalOptions?.pardotUserPrefillUrl || "";

    useEffect(() => {
        const abortController = new AbortController();

        const options = {
            signal: abortController.signal,
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ id: userEmail }),
        };

        if (isLoading) {
            if (pardotUserPrefillUrl && userEmail) {
                // attempt to get user data
                fetchPardotUser(pardotUserPrefillUrl, options, dispatch);
            } else if (userEmail === false) {
                // User doesn't have email set, stop fetching
                dispatch({ type: "STOP_LOADING" });
            }
        }

        return () => {
            abortController.abort();
        };
    }, [pardotUserPrefillUrl, userEmail, isLoading]);

    useEffect(() => {
        if (gatedStorage.length) {
            setLocalStorageSerialize("gatedFormStorage", gatedStorage);
        }
    }, [gatedStorage]);

    useEffect(() => {
        if (termsStorage.length) {
            setLocalStorageSerialize("termsOfUseStorage", termsStorage);
        }
    }, [termsStorage]);

    const updateUserEmail = (newUserEmail) => {
        if (newUserEmail !== userEmail) {
            dispatch({ type: "SET_PARDOT_EMAIL", userEmail: newUserEmail });
        }
    };

    const setGatedFormsStorage = (gatedFormStorageKey) => {
        if (
            !gatedStorage ||
            (isArray(gatedStorage) &&
                !gatedStorage.includes(gatedFormStorageKey))
        ) {
            const updatedGatedStorage = gatedStorage;

            updatedGatedStorage.push(gatedFormStorageKey);
            dispatch({
                type: "SET_GATED_SUBMISSION",
                gatedStorage: updatedGatedStorage,
            });
        }
    };

    const getGatedFormsStorage = useCallback(
        (gatedFormStorageKey) => {
            return (
                isArray(gatedStorage) &&
                gatedStorage.includes(gatedFormStorageKey)
            );
        },
        [gatedStorage],
    );

    const setDocTermsStorage = (docTermsStorageKey) => {
        if (
            !termsStorage ||
            (isArray(termsStorage) &&
                !termsStorage.includes(docTermsStorageKey))
        ) {
            const updatedTermsStorage = termsStorage;

            updatedTermsStorage.push(docTermsStorageKey);
            dispatch({
                type: "SET_TERMS_SUBMISION",
                termsStorage: updatedTermsStorage,
            });
        }
    };

    const getDocTermsStorage = useCallback(
        (docTermsStorageKey) => {
            return (
                isArray(termsStorage) &&
                termsStorage.includes(docTermsStorageKey)
            );
        },
        [termsStorage],
    );

    return (
        <PardotUserContext.Provider
            value={{
                isLoading,
                userEmail,
                updateUserEmail,
                userData,
                setDocTermsStorage,
                setGatedFormsStorage,
                getDocTermsStorage,
                getGatedFormsStorage,
            }}
        >
            {children}
        </PardotUserContext.Provider>
    );
};

export const usePardotUserContext = () => {
    const context = useContext(PardotUserContext);

    if (context === undefined) {
        throw new Error(
            "usePardotUserProvider must be used within a FormProvider",
        );
    }

    return context;
};
