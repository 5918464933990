import styled from "@emotion/styled";
import React from "react";
import PropTypes from "prop-types";

import { link } from "~/styles/typographyStyles";

import transition from "~/utilities/transition";

export const StyledButton = styled.button`
    ${transition({}, "color")};
    ${link};
    color: ${({ theme }) => theme.colors.typography.links};

    &:hover,
    &:focus {
        color: ${({ theme }) => theme.colors.typography.linksHover};
    }
`;

const TextButton = ({
    children,
    className,
    onClick,
    ariaLabel = "",
    ...props
}) => {
    const buttonProps = { onClick, className, type: "button" };

    if (ariaLabel) {
        buttonProps["aria-label"] = ariaLabel;
    }

    return (
        <StyledButton {...buttonProps} {...props}>
            {children}
        </StyledButton>
    );
};

TextButton.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    ariaLabel: PropTypes.string,
};

export default TextButton;
