import { map, forEach } from "lodash";
import Cookies from "js-cookie";

import trackCustomEvent from "~/utilities/trackCustomEvent";

export const formatCookieTypes = (cookieTypes) => {
    return map(cookieTypes, (cookie) => {
        const { title, optional, defaultDisabled, eventAction, expiration } =
            cookie;

        const name = title.replace(/\s+/g, "-").toLowerCase();
        const cookieExpiration = expiration ?? 180;
        const cookieExists = Cookies.get(eventAction);

        let defaultChecked;

        if (cookieExists) {
            defaultChecked = cookieExists === "granted";
        } else {
            defaultChecked = !optional || !defaultDisabled;
        }

        return {
            ...cookie,
            name,
            defaultChecked,
            cookieExists,
            expiration: cookieExpiration,
            disabled: !optional,
        };
    });
};

export const initCookies = (cookieTypes) => {
    if (cookieTypes.length) {
        forEach(cookieTypes, (cookie) => {
            const { defaultChecked, expiration, eventAction, cookieExists } =
                cookie;

            const defaultCookieValue = defaultChecked ? "granted" : "denied";

            if (!cookieExists) {
                Cookies.set(eventAction, defaultCookieValue, {
                    expires: expiration,
                });
            }
        });
    }
};

export const updateCookie = (eventAction, expiration, isEnabled) => {
    const newCookieValue = isEnabled ? "granted" : "denied";
    Cookies.set(eventAction, newCookieValue, {
        expires: expiration,
    });

    trackCustomEvent("gaEvent", {
        action: "Consent Updated",
        category: eventAction,
        value: newCookieValue,
    });
};
