import { merge, cloneDeep } from "lodash";

import {
    getLocalStorageItem,
    setLocalStorageItem,
    getLocalStorageSerialize,
} from "~/utilities/localStorageHelpers";

import { isBrowser } from "~/utilities/helpers";
import { upsertOption } from "~/utilities/reducer";

export const USER_EMAIL_KEY = "userEmail";

export const pardotUserState = {
    isLoading: true,
    fetchUser: false,
    gatedStorage: [],
    termsStorage: [],
    userEmail: null,
    userData: {},
};

export function initPardotUser() {
    let userEmail = getLocalStorageItem(USER_EMAIL_KEY);

    if (!userEmail) {
        userEmail = false;
    }

    const gatedStorage = getLocalStorageSerialize(
        "gatedFormStorage",
        pardotUserState.gatedStorage,
    );
    const termsStorage = getLocalStorageSerialize(
        "termsOfUseStorage",
        pardotUserState.termsStorage,
    );

    const userState = merge({}, pardotUserState, {
        gatedStorage,
        userEmail,
        termsStorage,
    });

    return userState;
}

export function pardotUserEmailReducer(state, action) {
    let updatedState = state;
    const currentState = cloneDeep(state);
    const { type } = action;
    switch (type) {
        case "FETCH_PARDOT_USER":
            updatedState = upsertOption(currentState, {
                fetchUser: true,
            });
            break;
        case "STOP_LOADING":
            updatedState = upsertOption(currentState, {
                isLoading: false,
            });
            break;
        case "SET_PARDOT_EMAIL":
            setLocalStorageItem(USER_EMAIL_KEY, action.userEmail);
            updatedState = upsertOption(currentState, {
                userEmail: action.userEmail,
                fetchUser: true,
            });
            break;
        case "SET_PARDOT_USER_DATA":
            updatedState = upsertOption(currentState, {
                isLoading: false,
                userData: action.userData,
            });
            break;
        case "SET_GATED_SUBMISSION":
            updatedState = upsertOption(currentState, {
                gatedStorage: action.gatedStorage,
            });
            break;
        case "SET_TERMS_SUBMISION":
            updatedState = upsertOption(currentState, {
                termsStorage: action.termsStorage,
            });
            break;
        default:
            if (isBrowser) {
                console.log(`Unkown Pardot User Action ${action.type}`);
            }
            break;
    }

    return updatedState;
}
