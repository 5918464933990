import { css } from "@emotion/react";

import transition from "~/utilities/transition";

const getButtonColor = (active, theme, color, disabled) => {
    switch (color) {
        case "white":
            return css`
                background-color: ${theme.palette.white};
                color: ${active ? theme.palette.teal : theme.palette.purple};

                &:hover,
                &:focus {
                    color: ${disabled
                        ? theme.palette.purple
                        : theme.palette.teal};
                }
            `;
        case "dark":
            return css`
                background-color: ${active
                    ? theme.palette.teal
                    : theme.palette.black};
                color: ${theme.palette.white};

                &:hover,
                &:focus {
                    background-color: ${disabled
                        ? theme.palette.black
                        : theme.palette.teal};
                }
            `;
        case "gray":
            return css`
                background-color: ${active
                    ? theme.palette.purple
                    : theme.palette.grayThree};
                color: ${active ? theme.palette.white : theme.palette.purple};

                &:hover,
                &:focus {
                    color: ${disabled
                        ? theme.palette.purple
                        : theme.palette.white};
                    background-color: ${disabled
                        ? theme.palette.grayThree
                        : theme.palette.purple};
                }
            `;
        case "purple":
            return css`
                background-color: ${active
                    ? theme.palette.teal
                    : theme.palette.purple};
                color: ${theme.palette.white};

                &:hover,
                &:focus {
                    background-color: ${disabled
                        ? theme.palette.purple
                        : theme.palette.teal};
                }
            `;
        case "teal":
            return css`
                background-color: ${active
                    ? theme.palette.black
                    : theme.palette.teal};
                color: ${theme.palette.white};

                &:hover,
                &:focus {
                    background-color: ${disabled
                        ? theme.palette.teal
                        : theme.palette.black};
                }
            `;
        default:
            return css`
                background-color: ${active
                    ? theme.palette.teal
                    : theme.palette.purple};
                color: ${theme.palette.white};

                &:hover,
                &:focus {
                    background-color: ${disabled
                        ? theme.palette.purple
                        : theme.palette.teal};
                }
            `;
    }
};

const buttonStyles = ({
    active,
    theme,
    color,
    large,
    noMinWidth,
    disabled,
}) => css`
    ${getButtonColor(active, theme, color, disabled)};
    ${transition({}, "color", "background-color")};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2rem;
    line-height: 1;
    font-weight: 700;
    opacity: ${disabled ? `.5` : `1`};
    padding: ${large ? `15px 24px` : `10px 24px`};
    border-radius: ${large
        ? theme.button.borderRadiusLarge
        : theme.button.borderRadius};
    min-width: ${large && !noMinWidth
        ? theme.button.minWidthLarge
        : theme.button.minWidth};
    cursor: ${disabled ? `not-allowed` : `pointer`};
`;

export default buttonStyles;
