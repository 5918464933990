import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import buttonStyles from "~/styles/buttonStyles";

import { buttonColorOptions, buttonColorDefault } from "~/constants/buttons";

const StyledButton = styled.button`
    ${buttonStyles};
`;

const Button = ({
    children,
    disabled,
    active = false,
    large = false,
    color = buttonColorDefault,
    type = "button",
    ariaLabel = "",
    noMinWidth = false,
    onClick = () => {},
    ...rest
}) => {
    const buttonProps = {
        active,
        color,
        large,
        onClick,
        type,
        noMinWidth,
        disabled,
        ...rest,
    };

    if (ariaLabel) {
        buttonProps["aria-label"] = ariaLabel;
    }
    return <StyledButton {...buttonProps}>{children}</StyledButton>;
};

Button.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node.isRequired,
    color: PropTypes.oneOf(buttonColorOptions),
    large: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(["button", "submit", "reset"]),
    ariaLabel: PropTypes.string,
    noMinWidth: PropTypes.bool,
};

export default Button;
