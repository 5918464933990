import { useStaticQuery, graphql, navigate } from "gatsby";
import React, {
    useContext,
    createContext,
    useState,
    useCallback,
    useEffect,
} from "react";
import queryString from "query-string";
import { find, filter, sortBy, map, reduce, isEmpty, some } from "lodash";

import { useLocationContext } from "~/context/LocationContext";

import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "~/utilities/localStorageHelpers";

const TranslationContext = createContext();

export const GLOBAL_LANGUAGE_PARAM = "language";

export const useAddQueryParams = (urlPermutations) => {
    const { location } = useLocationContext();
    const params = queryString.parse(location.search);

    if (isEmpty(params)) {
        return urlPermutations;
    }

    const excludeList = ["/knowledge-center/"];

    return map(urlPermutations, (permutation) => {
        const isExcluded = some(excludeList, (pathname) => {
            return location.pathname.includes(pathname);
        });

        const extraParams = isExcluded ? {} : params;

        const queryParams = {
            ...extraParams,
            [GLOBAL_LANGUAGE_PARAM]: permutation.languageCode,
        };

        return {
            ...permutation,
            linkUrl: `${permutation.linkUrl}?${queryString.stringify(
                queryParams,
            )}`,
        };
    });
};

export const addIsActiveFlag = (urlPermutations, activeCode) => {
    return map(urlPermutations, (permutation) => {
        return {
            ...permutation,
            isActive: activeCode === permutation.languageCode,
        };
    });
};

export const sortLanguageLinks = (urlPermutations) => {
    const sortOrders = {
        en: ["en", "zh-cn", "zh-tw"],
        "zh-cn": ["zh-cn", "zh-tw", "en"],
        "zh-tw": ["zh-tw", "zh-cn", "en"],
    };

    const activeLink = find(urlPermutations, { isActive: true });
    const activeCode = activeLink?.languageCode;

    const primaryOrder = sortOrders[activeCode] || sortOrders["en"];

    const primaryPermutations = filter(urlPermutations, (permutation) => {
        return (
            primaryOrder.includes(permutation.languageCode) &&
            !permutation.isActive
        );
    });

    const nonPrimaryPermutations = filter(urlPermutations, (permutation) => {
        return (
            !primaryOrder.includes(permutation.languageCode) &&
            !permutation.isActive
        );
    });

    const sortedPrimary = reduce(
        primaryOrder,
        (acc, languageCode) => {
            const permutation = find(primaryPermutations, { languageCode });

            if (permutation) {
                return [...acc, permutation];
            }

            return acc;
        },
        [],
    );

    const sortedNonPrimary = sortBy(
        nonPrimaryPermutations,
        ({ linkLabel }) => linkLabel,
    );

    if (activeLink) {
        return [activeLink, ...sortedPrimary, ...sortedNonPrimary];
    }

    return [...sortedPrimary, ...sortedNonPrimary];
};

export const parseLanguageCode = (languageCode) => {
    if (languageCode === "zh-hans") {
        return "zh-cn";
    }

    if (languageCode === "zh-hant") {
        return "zh-tw";
    }

    return languageCode;
};

const validateQueryCode = (queryCode, navigation, urlPermutations) => {
    const isValidNav = some(navigation?.nodes, { languageCode: queryCode });
    const isValidPer = some(urlPermutations, { languageCode: queryCode });

    if (isValidNav || isValidPer) {
        return queryCode;
    }

    return "";
};

const getQueryCode = (location, navigation, urlPermutations, pageCode) => {
    const params = queryString.parse(location.search);
    const queryParam = params[GLOBAL_LANGUAGE_PARAM];
    const queryCode = validateQueryCode(
        queryParam,
        navigation,
        urlPermutations,
    );

    const ignore = pageCode !== "en";

    if (!queryCode || ignore) {
        return "";
    }

    return queryCode;
};

const shouldUsePageCode = (pageCode, navigation, activeCode) => {
    const navContainsActiveCode = some(navigation?.nodes, {
        languageCode: activeCode,
    });
    const activeCodeIsStale = pageCode !== "en" && activeCode !== pageCode;
    return !navContainsActiveCode || activeCodeIsStale;
};

const maybeRedirect = (urlPermutations, activeCode, location) => {
    const en = find(urlPermutations, { languageCode: "en" })?.linkUrl;
    const params = queryString.parse(location.search);

    const url = find(urlPermutations, {
        linkUrl: `${en}${activeCode}/`,
    })?.linkUrl;

    let queryParams = "";

    if (!isEmpty(params)) {
        queryParams = `?${queryString.stringify({
            ...params,
            [GLOBAL_LANGUAGE_PARAM]: activeCode,
        })}`;
    }

    if (url && url !== location.pathname) {
        navigate(`${url}${queryParams}`);
    }
};

export const TranslationProvider = ({ children, pageProps }) => {
    const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);

    const initialCode = pageProps?.pageContext?.languageCode || "en";
    const [activeCode, setActiveCode] = useState(initialCode);

    const { location } = useLocationContext();

    const { navigation } = useStaticQuery(graphql`
        query TranslationContextStaticQuery {
            navigation: allWpNavigation {
                nodes {
                    languageCode
                }
            }
        }
    `);

    useEffect(() => {
        const handlePopState = () => {
            setActiveCode("en");
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    useEffect(() => {
        setLocalStorageItem("ws-active-code", activeCode);
    }, [activeCode]);

    const setPageCode = useCallback(
        (pageCode, urlPermutations) => {
            setActiveCode((activeCode) => {
                if (pageCode === "en") {
                    maybeRedirect(urlPermutations, activeCode, location);
                }

                if (shouldUsePageCode(pageCode, navigation, activeCode)) {
                    return pageCode;
                }

                return activeCode;
            });
        },
        [navigation, location],
    );

    const setQueryCode = useCallback(
        (pageCode, urlPermutations) => {
            const queryCode = getQueryCode(
                location,
                navigation,
                urlPermutations,
                pageCode,
            );

            const savedCode = getLocalStorageItem("ws-active-code");
            const code = queryCode || savedCode || pageCode;

            setActiveCode(code);
        },
        [location, navigation],
    );

    const initialize = useCallback(
        (pageCode, urlPermutations) => {
            if (isInitialPageLoad) {
                setQueryCode(pageCode, urlPermutations);
                setIsInitialPageLoad(false);
            } else {
                setPageCode(pageCode, urlPermutations);
            }
        },
        [setQueryCode, setPageCode, isInitialPageLoad],
    );

    return (
        <TranslationContext.Provider
            value={{
                initialize,
                setActiveCode,
                activeCode,
            }}
        >
            {children}
        </TranslationContext.Provider>
    );
};

export const useTranslationContext = () => {
    const context = useContext(TranslationContext);

    if (context === undefined) {
        throw new Error(
            "useTranslationContext must be used within a TranslationProvider",
        );
    }

    return context;
};
