import { find, has, isEmpty, reduce } from "lodash";

import {
    setLocalStorageSerialize,
    getLocalStorageSerialize,
} from "~/utilities/localStorageHelpers";

import { fetchInline } from "~/utilities/fetchHelper";
import { formatOn24EventDate, getUTCFormat } from "~/utilities/formatDates";

class On24Form {
    constructor(props) {
        this.props = props;
    }

    static parseOn24Props({
        isOn24Form,
        on24EventId,
        on24EventKey,
        on24EventDate,
    }) {
        if (isOn24Form && on24EventId && on24EventKey) {
            const key = `${on24EventId}-${on24EventKey}`;
            const storageData = getLocalStorageSerialize(key);
            return {
                on24EventId,
                on24EventKey,
                on24EventDate,
                isOn24Form,
                storageData,
            };
        }
        return null;
    }

    formatEventData(formattedData) {
        const { on24Props, formInformation } = this.props;

        const getId = (field) => {
            const results = field.match(/[0-9]+/);
            let id = 0;
            if (results) {
                id = parseInt(results[0]);
            }
            return id;
        };

        const data = reduce(
            formattedData,
            (acc, value, key) => {
                const id = getId(key);

                const field = find(formInformation?.formFields, { id });

                if (has(field, "on24Field") && field.on24Field) {
                    return {
                        ...acc,
                        [field.on24Field]: value,
                    };
                }

                return acc;
            },
            {},
        );

        if (isEmpty(data)) {
            return data;
        }

        return {
            ...data,
            eventId: on24Props.on24EventId,
        };
    }

    async makeOn24Request(formattedData) {
        try {
            const { on24WebinarEndpoint } = this.props;
            const eventData = this.formatEventData(formattedData);

            if (isEmpty(eventData)) {
                return {
                    Message: "Failure",
                    Data: {
                        message: "Your submission failed. Please try again!",
                    },
                };
            }

            const url = `${process.env.GATSBY_ADMIN_SITE_URL}${on24WebinarEndpoint}`;

            return await fetchInline(url, {
                method: "POST",
                body: JSON.stringify(eventData),
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (e) {
            return {
                Message: "Failure",
                Data: {
                    message: e.message,
                },
            };
        }
    }

    saveOn24Data(on24Link, userEmail) {
        const { on24Props } = this.props;
        const key = `${on24Props.on24EventId}-${on24Props.on24EventKey}`;

        let eventDate = null;

        if (on24Props.on24EventDate) {
            eventDate = {
                utcDate: getUTCFormat(new Date(on24Props.on24EventDate)),
                formattedDate: formatOn24EventDate(on24Props.on24EventDate),
            };
        }

        const storageData = {
            ...on24Props,
            on24Link,
            userEmail,
            eventDate,
        };

        setLocalStorageSerialize(key, storageData);
    }

    openOn24Link(on24Link) {
        const isOpen = window.open(on24Link, "_blank");
        if (!isOpen) {
            window.open(on24Link, "_self");
        }
    }

    async handleOn24Request(formattedData, userEmail) {
        const response = await this.makeOn24Request(formattedData);
        const { on24Props } = this.props;

        if (response?.Message === "Success") {
            const on24Link = `https://event.on24.com/wcc/r/${on24Props.on24EventId}/${on24Props.on24EventKey}?mode=login&email=${userEmail}`;
            return {
                userEmail,
                on24RunOnSubmit: () => {
                    this.saveOn24Data(on24Link, userEmail);
                    this.openOn24Link(on24Link);
                },
            };
        } else {
            const message =
                response?.Data?.message ||
                "Your submission failed. Please try agian!";

            const exception = {
                message: message,
                code: "on24_error",
            };

            return exception;
        }
    }
}

export default On24Form;
