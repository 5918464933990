import styled from "@emotion/styled";
import React from "react";
import PropTypes from "prop-types";

import BaseLink from "~/components/links/BaseLink";

import { link } from "~/styles/typographyStyles";

import transition from "~/utilities/transition";

const StyledBaseLink = styled(BaseLink)`
    ${transition({}, "color")};
    ${link};
    color: ${({ theme }) => theme.colors.typography.links};

    &:hover {
        color: ${({ theme }) => theme.colors.typography.linksHover};
    }
`;

const TextLink = ({
    className,
    children,
    to,
    external = false,
    onClick,
    ...rest
}) => (
    <StyledBaseLink {...{ to, external, className, onClick, ...rest }}>
        {children}
    </StyledBaseLink>
);

TextLink.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
    external: PropTypes.bool,
    onClick: PropTypes.func,
};

export default TextLink;
