import { useStaticQuery, graphql } from "gatsby";
import { forEach } from "lodash";
import Cookies from "js-cookie";

const getCurrentConsent = (cookieTypes) => {
    let consent = {};
    if (cookieTypes.length) {
        forEach(cookieTypes, (cookie) => {
            const { eventAction } = cookie;
            const value = Cookies.get(eventAction);

            consent[eventAction] = value === "granted";
        });
    }

    return consent;
};

const useCookieConsent = () => {
    const { wp } = useStaticQuery(graphql`
        query StaticUseCookieConsentQuery {
            wp {
                gdprOptionsPage {
                    cookieNotice {
                        cookieConsentModal {
                            cookieTypes {
                                eventAction
                            }
                        }
                    }
                }
            }
        }
    `);

    const cookieConsentModal =
        wp?.gdprOptionsPage?.cookieNotice?.cookieConsentModal;

    const consent = getCurrentConsent(cookieConsentModal?.cookieTypes);

    const {
        analytical_consent: analyticalConsent,
        marketing_consent: marketingConsent,
    } = consent;

    return !analyticalConsent || !marketingConsent;
};

export default useCookieConsent;
