import { split, indexOf } from "lodash";
import { nanoid } from "nanoid";

import { fetchInline } from "~/utilities/fetchHelper";

import logo from "~/images/channels4_profile.jpg";

export async function getVimeoThumbnail(url) {
    try {
        const vimeoRequest = await fetchInline(
            `https://vimeo.com/api/oembed.json?url=${url}`,
        );

        if (vimeoRequest.video_id) {
            return vimeoRequest.thumbnail_url;
        }
    } catch (e) {
        console.log(e);
    }
}

export const getYoutubeThumbnailFromUrl = (url) => {
    const urlArr = split(url, "v=");

    if (urlArr.length) {
        let videoId = urlArr[1];
        const ampersandPosition = indexOf(videoId, "&");

        if (ampersandPosition !== -1) {
            videoId = videoId.substring(0, ampersandPosition);
        }

        return `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
    }

    return "";
};

export const getUrl = (documentsAttributes) => {
    const isEmbed = documentsAttributes?.isEmbed;
    const cloudfrontUrl = documentsAttributes?.video?.mediaItemUrl ?? "";
    const url = documentsAttributes?.videoUrl ?? "";

    if (isEmbed) {
        return url;
    }

    return cloudfrontUrl;
};

export const getPoster = (documentsAttributes) => {
    const isEmbed = documentsAttributes?.isEmbed;
    const poster = documentsAttributes?.videoPosterUrl ?? logo;
    let cloudfrontPoster = "";

    if (documentsAttributes?.video?.posterURL) {
        cloudfrontPoster = documentsAttributes.video.posterURL;
    }

    if (documentsAttributes?.videoPosterOverride?.sourceUrl) {
        cloudfrontPoster = documentsAttributes.videoPosterOverride.sourceUrl;
    }

    if (isEmbed) {
        return poster;
    }

    return cloudfrontPoster;
};

export const formatDownloadVideo = (download) => {
    const { documentsAttributes } = download;
    const title = download.title ?? "";
    const isVideo = documentsAttributes.isVideo;
    const url = getUrl(documentsAttributes);
    const poster = getPoster(documentsAttributes);
    const description = documentsAttributes.videoDescription ?? "";

    if (isVideo && url) {
        const isEmbed = !!documentsAttributes.isEmbed;
        return {
            title,
            url,
            isEmbed,
            description,
            poster,
            id: nanoid(),
        };
    }

    return null;
};
