import { useStaticQuery, graphql } from "gatsby";
import { find, reduce, toLower } from "lodash";

import {
    useTranslationContext,
    parseLanguageCode,
} from "~/context/TranslationContext";

/**
 *
 * @param { Object } kcTemplateStrings
 * @param { string } text
 * @param { string } activeCode
 * @param { ?string } key
 *
 * @returns { string }
 */
const getTranslation = (kcTemplateStrings, text, activeCode, key = "") => {
    const kcTemplateString = find(kcTemplateStrings, (kcTemplateString) => {
        const keyIsEqual = key === kcTemplateString.key;
        const strIsEqual = toLower(text) === toLower(kcTemplateString.string);
        return keyIsEqual || strIsEqual;
    });

    const translation = find(kcTemplateString?.translations, (translation) => {
        const parsedCode = parseLanguageCode(translation.languageCode);
        return parsedCode === activeCode;
    });

    return translation?.translatedString || text;
};

/**
 * @type {{ text: !string, handle: !string, key: ?string }} stringObject
 *
 * @param { stringObject[] } strings
 * @param { ?string } languageCode
 *
 * @returns { Object }
 */
const useStringTranslation = (strings, languageCode = "") => {
    const { activeCode } = useTranslationContext();

    const { wp } = useStaticQuery(graphql`
        query UseStringTranslationQuery {
            wp {
                kcTemplateStrings: templateStringsTranslations {
                    key: name
                    string: registeredString
                    translations {
                        languageCode
                        translatedString
                    }
                }
            }
        }
    `);

    const kcTemplateStrings = wp?.kcTemplateStrings;

    const code = languageCode || activeCode;

    return reduce(
        strings,
        (acc, string) => {
            const kcTemplateString = getTranslation(
                kcTemplateStrings,
                string.text,
                code,
                string.key,
            );

            return {
                ...acc,
                [string.handle]: kcTemplateString,
            };
        },
        {},
    );
};

export default useStringTranslation;
