import React from "react";

import BaseModal from "~/components/BaseModal";
import PropTypes from "prop-types";

const ConditionalModalWrapper = ({
    children,
    modalForm = false,
    isOpen = false,
    onRequestClose = () => {},
}) => {
    let defaultChildren = <>{children}</>;

    if (modalForm) {
        defaultChildren = isOpen ? (
            <BaseModal {...{ isOpen, onRequestClose }}>{children}</BaseModal>
        ) : null;
    }

    return defaultChildren;
};

export default ConditionalModalWrapper;

ConditionalModalWrapper.propTypes = {
    modalForm: PropTypes.bool,
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
};
