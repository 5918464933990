import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import BaseLink from "~/components/links/BaseLink";

import buttonStyles from "~/styles/buttonStyles";

import { buttonColorOptions, buttonColorDefault } from "~/constants/buttons";

const Button = styled(BaseLink)`
    ${buttonStyles};
`;

const ButtonLink = ({
    active = false,
    children,
    color = buttonColorDefault,
    external = false,
    to,
    large = false,
    lang,
    onClick,
    ariaLabel = "",
    disabled = false,
    className = "",
}) => (
    <Button
        {...{
            active,
            color,
            external,
            large,
            to,
            onClick,
            lang,
            ariaLabel,
            disabled,
        }}
    >
        {children}
    </Button>
);

ButtonLink.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node.isRequired,
    color: PropTypes.oneOf(buttonColorOptions),
    external: PropTypes.bool,
    large: PropTypes.bool,
    to: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    ariaLabel: PropTypes.string,
    disabled: PropTypes.bool,
};

export default ButtonLink;
