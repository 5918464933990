import { css } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React, { lazy, useRef, useState, Suspense } from "react";
import ReactPlayerLazy from "react-player/lazy";

import SectionWrapper from "~/components/layout/SectionWrapper";
import ContentContainer from "~/components/layout/ContentContainer";

import ErrorBoundary from "~/components/ErrorBoundary";
import Form from "~/queryBlocks/Form";
import Skeleton from "~/components/loaders/Skeleton";

import { caption as captionStyles } from "~/styles/typographyStyles";

import { sanitizeAndParseHTML } from "~/utilities/helpers";
import { trackReactPlayerEvents } from "~/utilities/trackVideoEvents";

import useCookieConsent from "~/hooks/useCookieConsent";

const VideoJS = lazy(() => import("~/components/VideoJS"));

const getPaddingBottom = ({ aspectRatio }) => {
    switch (aspectRatio) {
        case "4by3":
            return css`
                padding-bottom: 75%;
            `;
        default:
            return css`
                padding-bottom: 56.25%;
            `;
    }
};

const SmallWrapper = styled.div`
    margin: 0 auto;
    max-width: 900px;
`;

const PlayerWrapper = styled.div`
    ${getPaddingBottom};
    position: relative;

    .video-js {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const Caption = styled.div`
    ${captionStyles};
    margin-top: 10px;
    text-align: center;
`;

const ReactPlayer = ({
    videoCategory,
    gatedFormValidator,
    gatedFormId,
    setIsOpen,
    goToNextVideo,
    url,
}) => {
    const doNotTrack = useCookieConsent();
    const ref = useRef();
    const eventTracking = trackReactPlayerEvents(videoCategory);

    const gatedFormHandler = () => {
        let wasSubmitted = false;

        if (gatedFormValidator) {
            wasSubmitted = gatedFormValidator();
        }

        const video = ref?.current?.wrapper?.querySelector("video");

        if (gatedFormId && video && !wasSubmitted) {
            video.pause();
            video.currentTime = 0;
            setIsOpen(true);
        }
    };

    const videoEvents = {
        ...eventTracking,
        onEnded: () => {
            eventTracking.onEnded();
            if (goToNextVideo) {
                goToNextVideo();
            }
        },
        onPlay: () => {
            eventTracking.onPlay();
            gatedFormHandler();
        },
    };

    const reactPlayerConfig = {
        vimeo: {
            playerOptions: {
                dnt: doNotTrack,
            },
        },
        youtube: {
            embedOptions: {
                host: doNotTrack
                    ? "https://www.youtube-nocookie.com"
                    : "https://www.youtube.com",
            },
        },
    };
    return (
        <ReactPlayerLazy
            {...{ ...videoEvents, url, ref }}
            className="react-player"
            width="100%"
            height="100%"
            controls={true}
            config={reactPlayerConfig}
        />
    );
};

const VideoEmbed = ({
    caption = "",
    isEmbed = true,
    poster = "",
    url,
    className = "",
    aspectRatio = "",
    options = {
        autoplay: false,
        loop: false,
        muted: false,
        playsInline: false,
        controls: true,
    },
    title = "",
    smallWidth = false,
    goToNextVideo = () => {},
    gatedFormId = null,
    runOnSubmit = null,
    gatedFormValidator = null,
    runOnSubmitActions,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const WrapperComponent = smallWidth ? SmallWrapper : ErrorBoundary;

    let videoCategory = url;

    if (caption) {
        videoCategory = caption;
    }

    if (title) {
        videoCategory = title;
    }

    return (
        <>
            {gatedFormId ? (
                <Form
                    formId={Number.parseInt(gatedFormId, 10)}
                    isModalOpenProp={isOpen}
                    closeModalProp={() => setIsOpen(false)}
                    modalForm={true}
                    hideModalButton={true}
                    runOnSubmit={runOnSubmit}
                    runOnSubmitActions={runOnSubmitActions}
                />
            ) : null}
            <SectionWrapper noMarginBottom>
                <ContentContainer>
                    <WrapperComponent>
                        <PlayerWrapper
                            aspectRatio={
                                aspectRatio ??
                                (className && className?.indexOf("4-3") !== -1
                                    ? "4by3"
                                    : "")
                            }
                        >
                            {isEmbed ? (
                                <ReactPlayer
                                    {...{
                                        videoCategory,
                                        gatedFormValidator,
                                        gatedFormId,
                                        setIsOpen,
                                        goToNextVideo,
                                        url,
                                    }}
                                />
                            ) : (
                                <Suspense
                                    fallback={<Skeleton variant="block" />}
                                >
                                    <VideoJS
                                        {...{
                                            url,
                                            poster,
                                            options,
                                            videoCategory,
                                            goToNextVideo,
                                            gatedFormId,
                                            isOpen,
                                            setIsOpen,
                                            gatedFormValidator,
                                        }}
                                        width="100%"
                                        height="100%"
                                    />
                                </Suspense>
                            )}
                        </PlayerWrapper>

                        {caption ? (
                            <Caption>{sanitizeAndParseHTML(caption)}</Caption>
                        ) : null}
                    </WrapperComponent>
                </ContentContainer>
            </SectionWrapper>
        </>
    );
};

VideoEmbed.propTypes = {
    caption: PropTypes.string,
    title: PropTypes.string,
    isEmbed: PropTypes.bool,
    poster: PropTypes.string,
    url: PropTypes.string.isRequired,
    className: PropTypes.string,
    options: PropTypes.shape({
        autoplay: PropTypes.bool,
        loop: PropTypes.bool,
        muted: PropTypes.bool,
        playsInline: PropTypes.bool,
        controls: PropTypes.bool,
    }),
    smallWidth: PropTypes.bool,
    aspectRatio: PropTypes.string,
    goToNextVideo: PropTypes.func,
    gatedFormId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    runOnSubmit: PropTypes.func,
    gatedFormValidator: PropTypes.func,
};

export default VideoEmbed;
