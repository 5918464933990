import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import FaIcon from "~/components/FaIcon";

import { link } from "~/styles/typographyStyles";
import { rotateCentered } from "~/styles/keyframes";

import transition from "~/utilities/transition";

const Button = styled.button`
    ${transition({}, "color")};
    ${link};
    color: ${({ theme, color }) =>
        color === "whiteAlt"
            ? theme.palette.white
            : theme.colors.typography.links};
    display: inline-flex;
    align-items: center;
    font-weight: ${({ fontWeight }) => fontWeight ?? "bold"};
    font-size: ${({ fontSize }) => fontSize ?? "inherit"};
    border-bottom: 2px solid transparent;
    opacity: ${({ disabled }) => (disabled ? `.5` : `1`)};
    ${({ disabled }) => (disabled ? "pointer-events: none;" : "")}

    &:hover,
    &:focus {
        ${({ theme, color }) =>
            color === "whiteAlt"
                ? `border-color: ${theme.palette.white};`
                : `color: ${theme.colors.typography.linksHover};`}
    }
`;

const Icon = styled(FaIcon)`
    font-size: ${({ fontSize }) => fontSize ?? "2rem"};
    margin-right: 10px;
`;

const LoadingIcon = styled(FaIcon)`
    position: relative;
    font-size: 2rem;
    margin-right: 10px;
    top: 10px;
    animation: ${rotateCentered} 1s linear infinite normal forwards;
`;

const IconButton = ({
    children,
    onClick,
    color,
    iconLibrary = "fas",
    icon = "",
    loading = false,
    fontSize = null,
    fontWeight = null,
    ...props
}) => (
    <Button type="button" {...{ onClick, color }} {...props}>
        {icon ? (
            loading ? (
                <LoadingIcon name={icon} {...{ iconLibrary }} />
            ) : (
                <Icon name={icon} {...{ iconLibrary }} />
            )
        ) : null}
        {children}
    </Button>
);

IconButton.propTypes = {
    fontWeight: PropTypes.string,
    fontSize: PropTypes.string,
    icon: PropTypes.string,
    iconLibrary: PropTypes.string,
    loading: PropTypes.bool,
    children: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default IconButton;
