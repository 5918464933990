import { ErrorMessage } from "@hookform/error-message";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import React from "react";
import { toString, trim } from "lodash";

import { ariaAlert, getErrorMessage } from "~/utilities/formHelpers";

import { Error, Label } from "./Input";

export const textAreaStyles = ({
    backgroundColor,
    theme,
    error,
    expandable,
    disabled,
}) => css`
    height: ${expandable ? `5rem` : `22rem`};
    width: 100%;
    border: 2px solid ${theme.palette.grayOne};
    resize: ${expandable ? `vertical` : `inherit`};
    cursor: ${disabled ? `not-allowed` : null};
    background-color: ${disabled
        ? theme.palette.grayFour
        : backgroundColor === "white"
          ? theme.palette.white
          : theme.palette.grayThree};

    ::placeholder {
        color: ${theme.palette.grayOne};
        opacity: 1;
    }

    &:focus,
    &:focus-visible {
        border: ${error
            ? `2px solid ${theme.palette.red}`
            : `2px solid ${theme.palette.black}`};
        outline: none;
    }
`;

const StyledTextarea = styled.textarea`
    ${textAreaStyles};
`;

const getValidationRules = (required, errorMessage, label) => {
    if (!required) {
        return { required: false };
    }

    const requiredMsg = getErrorMessage(errorMessage, label);

    return {
        required: requiredMsg,
        validate: (value) => (trim(toString(value)) ? null : requiredMsg),
    };
};

const Textarea = ({
    label,
    name,
    register,
    errors = null,
    placeholder = "",
    required = false,
    disabled = false,
    expandable = true,
    backgroundColor = "gray",
    autocompleteAttribute = "on",
    errorMessage = "",
}) => (
    <>
        <Label htmlFor={name}>
            {label}
            {required ? <span>*</span> : ""}
        </Label>
        <StyledTextarea
            {...{
                name,
                disabled,
                placeholder,
                expandable,
                backgroundColor,
            }}
            {...register(
                name,
                getValidationRules(required, errorMessage, label),
            )}
            autoComplete={autocompleteAttribute}
            error={errors[name]}
            id={name}
            aria-required={required}
            aria-label={ariaAlert(label, errors[name])}
        />

        <ErrorMessage
            {...{ errors, name }}
            render={({ message }) => <Error>{message}</Error>}
        />
    </>
);

Textarea.propTypes = {
    errors: PropTypes.shape({}),
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    expandable: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    backgroundColor: PropTypes.oneOf(["white", "gray"]),
    autocompleteAttribute: PropTypes.string,
    errorMessage: PropTypes.string,
};

export default Textarea;
