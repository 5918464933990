import { ErrorMessage } from "@hookform/error-message";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import { camelCase, isEmpty } from "lodash";

import UserContent from "~/components/UserContent";
import ErrorBoundary from "~/components/ErrorBoundary";

import FaIcon from "../FaIcon";

import { Error, Label } from "./Input";

import transition from "~/utilities/transition";
import { sanitizeAndParseHTML } from "~/utilities/helpers";
import {
    getLegend,
    getErrorMessage,
    getAriaLabelWithText,
} from "~/utilities/formHelpers";

export const FieldWrapper = styled.div``;
export const GroupedFieldWrapper = styled.ul``;

export const Input = styled.input`
    position: absolute;
    cursor: ${({ disabled }) => (disabled ? `not-allowed` : "pointer")};
`;

export const OptionWrapper = styled.div`
    position: relative;
`;
export const GroupedOptionWrapper = styled.li`
    position: relative;
    list-style: none;
`;

export const CheckIcon = styled(FaIcon)`
    ${transition({}, "opacity")};
    opacity: 0;
    font-size: 1.4rem;
    position: absolute;
    top: 4px;
    left: 4px;
    color: ${({ theme }) => theme.palette.white};
    cursor: ${({ disabled }) => (disabled ? `not-allowed` : "pointer")};
`;

export const CheckboxLabel = styled(Label)`
    font-size: 1.8rem;
    cursor: ${({ disabled }) => (disabled ? `not-allowed` : "pointer")};
    display: flex;

    &:before {
        content: "";
        height: 20px;
        width: 20px;
        background-color: ${({ theme, disabled }) =>
            disabled ? theme.palette.grayFour : theme.palette.grayThree};
        cursor: ${({ disabled }) => (disabled ? `not-allowed` : "pointer")};
        margin-right: 15px;
        padding: 10px;
        border: ${({ theme, error }) =>
            error
                ? `1px solid ${theme.palette.red}`
                : `1px solid ${theme.palette.grayOne}`};
        border-radius: 2px;
        position: relative;
    }

    ${Input}:checked + & {
        &:before {
            border: ${({ theme }) => `1px solid ${theme.palette.grayThree}`};
            background-color: ${({ theme }) => theme.palette.grayOne};
        }

        ${CheckIcon} {
            opacity: 1;
        }
    }

    ${Input}:focus-visible + & {
        outline: ${({ theme, error }) =>
            `2px solid ${error ? theme.palette.red : theme.palette.black}`};
    }

    @supports not selector(:focus-visible) {
        ${Input}:focus + & {
            outline: ${({ theme, error }) =>
                `2px solid ${error ? theme.palette.red : theme.palette.black}`};
        }
    }
`;

export const DynamicFieldWrapper = ({ optionsLength, children, ...props }) => {
    let fieldWrapperReturn = <FieldWrapper {...props}>{children}</FieldWrapper>;
    if (optionsLength > 1) {
        fieldWrapperReturn = (
            <GroupedFieldWrapper role="list" {...props}>
                {children}
            </GroupedFieldWrapper>
        );
    }
    return fieldWrapperReturn;
};

const DynamicOptionWrapper = ({ optionsLength, children, ...props }) => {
    let OptionWrapperReturn = (
        <OptionWrapper {...props}>{children}</OptionWrapper>
    );
    if (optionsLength > 1) {
        OptionWrapperReturn = (
            <GroupedOptionWrapper {...props}>{children}</GroupedOptionWrapper>
        );
    }
    return OptionWrapperReturn;
};

const Checkbox = ({
    label,
    name,
    register,
    options,
    errors = null,
    required = false,
    disabled = false,
    labelPlacement = "",
    errorMessage = "",
}) => {
    if (isEmpty(options)) {
        return null;
    }
    const optionsLength = options?.length;
    return (
        <ErrorBoundary>
            {getLegend(labelPlacement, required, label)}
            <DynamicFieldWrapper
                aria-labelledby={`${camelCase(label)}FieldLabel`}
                required={required}
                {...{ optionsLength }}
            >
                {options.map(({ text, value }, index) => (
                    <DynamicOptionWrapper
                        {...{ optionsLength }}
                        aria-required={required}
                        key={index}
                    >
                        <Input
                            {...{ disabled, name, value }}
                            {...register(name, {
                                required: required
                                    ? getErrorMessage(errorMessage, label)
                                    : false,
                            })}
                            type="checkbox"
                            id={`${name}_${index}`}
                            aria-label={getAriaLabelWithText(
                                label,
                                text,
                                errors[name],
                            )}
                        />

                        <CheckboxLabel
                            htmlFor={`${name}_${index}`}
                            {...{ disabled }}
                            error={errors[name]}
                        >
                            <CheckIcon {...{ disabled }} name="check" />
                            <UserContent>
                                {required &&
                                labelPlacement === "hidden_label" ? (
                                    <span>*</span>
                                ) : (
                                    ""
                                )}
                                {sanitizeAndParseHTML(text)}
                            </UserContent>
                        </CheckboxLabel>
                    </DynamicOptionWrapper>
                ))}
                <ErrorMessage
                    {...{ errors, name }}
                    render={({ message }) => <Error>{message}</Error>}
                />
            </DynamicFieldWrapper>
        </ErrorBoundary>
    );
};

Checkbox.propTypes = {
    errors: PropTypes.shape({}),
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    labelPlacement: PropTypes.string,
    errorMessage: PropTypes.string,
};

export default Checkbox;
