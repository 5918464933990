export const palette = {
    black: `#000000`,
    white: `#FFFFFF`,
    teal: `#0081A1`,
    lightTeal: `#36DCEE`,
    blueOne: `#3B4184`,
    blueTwo: `#3B6F97`,
    purple: `#58238C`,
    lightPurple: `#FBF8FF`,
    purpleBorder: `#C7A8DE`,
    grayOne: `#646262`,
    grayTwo: `#8A8B8C`,
    grayThree: `#F1F1F1`,
    grayFour: `#C9C6C6`,
    grayFive: `#E3E3E3`,
    graySix: `#C4C2C2`,
    borderGray: `#E3E3E3`,
    red: `#AD0808`,
    darkRed: `#6a1921`,
    lightRed: `#f8d7d9`,
    lightGreen: `#d1e7dd`,
    darkGreen: `#0f5132`,
    focusBlue: `#2E96FF`,
    burntYellow: "#FFB700",
    transparent: "transparent",
};

export const gradients = {
    defaultDark: `linear-gradient(94deg, #000000 45%, rgba(1,1,1,0.00) 87%)`,
    purple: `linear-gradient(94deg, #58238C 45%, rgba(1,1,1,0.00) 87%)`,
    tealPurple: `linear-gradient(148deg, #0081A1 10%, #582C83 67%)`,
};

export const colors = {
    typography: {
        defaultLightBackground: palette.black,
        defaultDarkBackground: palette.white,
        links: palette.purple,
        linksHover: palette.teal,
    },
    backgroundColor: {
        defaultDark: palette.black,
        defaultLight: palette.white,
        section: palette.grayThree,
        sectionDarker: palette.grayOne,
        defaultDarkGradient: gradients.defaultDark,
        hover: palette.grayOne,
    },
};

export const sectionBackgroundColor = {
    black: palette.black,
    gray: palette.grayThree,
    white: palette.white,
    purple: palette.purple,
    teal: palette.teal,
    blue: palette.blueTwo,
    purpleBlue: palette.blueOne,
    transparent: palette.transparent,
};

export const statCardColors = {
    black: palette.black,
    purple: palette.purple,
    purpleBlue: palette.blueOne,
    blue: palette.blueTwo,
    teal: palette.teal,
    hover: palette.grayOne,
    custom: "",
};

export const iconLinkColors = {
    black: {
        color: palette.black,
        hover: palette.teal,
    },
    white: {
        color: palette.white,
        hover: palette.purple,
    },
    whiteAlt: {
        color: palette.white,
        hover: palette.teal,
    },
    purple: {
        color: palette.purple,
        hover: palette.teal,
    },
    teal: {
        color: palette.teal,
        hover: palette.black,
    },
    gray: {
        color: palette.grayOne,
        hover: palette.teal,
    },
};

export const button = {
    borderRadius: `30px`,
    borderRadiusLarge: `25px`,
    minWidth: `116px`,
    minWidthLarge: `245px`,
};

export const spacing = {
    smallest: `10px`,
    extraSmall: `22px`,
    smaller: `26px`,
    small: `33px`,
    medium: `42px`,
    large: `55px`,
    extraLarge: `65px`,
    veryExtraLarge: `100px`,
};

export const layout = {
    maxContentWidth: `1320px`,
    maxContentWidthNumber: 1320,
    gutter: `30px`,
    gutterNumber: 30,
    halfGutter: `15px`,
    doubleGutter: `60px`,
    paragraphSpacing: `30px`,
    sectionPadding: spacing.veryExtraLarge,
    sectionSidePadding: `80px`,
    sectionSidePaddingMobile: `40px`,
    pageHeaderPadding: spacing.extraLarge,
    sectionMargin: spacing.extraLarge,
    subNavWidth: `290px`,
    subNavWidthNumber: 290,
};

export const fontFamily = {
    primary: `'Source Sans Pro', sans-serif;`,
    secondary: `'Roboto', sans-serif;`,
};

export const breakpointNumbers = {
    mobilePortrait: 375,
    mobilePortraitMax: 374,
    mobileLandscape: 568,
    mobileLandscapeMax: 567,
    tabletPortrait: 768,
    tabletPortraitMax: 767,
    tabletLandscape: 1150,
    tabletLandscapeMax: 1149,
    desktop: 1280,
    desktopMax: 1279,
    largeDesktop: 1440,
    largeDesktopMax: 1439,
};

export const breakpoints = {
    mobilePortrait: `${breakpointNumbers.mobilePortrait}px`,
    mobilePortraitMax: `${breakpointNumbers.mobilePortraitMax}px`,
    mobileLandscape: `${breakpointNumbers.mobileLandscape}px`,
    mobileLandscapeMax: `${breakpointNumbers.mobileLandscapeMax}px`,
    tabletPortrait: `${breakpointNumbers.tabletPortrait}px`,
    tabletPortraitMax: `${breakpointNumbers.tabletPortraitMax}px`,
    tabletLandscape: `${breakpointNumbers.tabletLandscape}px`,
    tabletLandscapeMax: `${breakpointNumbers.tabletLandscapeMax}px`,
    desktop: `${breakpointNumbers.desktop}px`,
    desktopMax: `${breakpointNumbers.desktopMax}px`,
    largeDesktop: `${breakpointNumbers.largeDesktop}px`,
    largeDesktopMax: `${breakpointNumbers.largeDesktopMax}px`,
};

export const zIndex = {
    smallest: 1,
    dropdowns: 5,
    stickyJumpLinks: 10,
    headerOverlay: 25,
    header: 30,
    navWrapper: 51,
    cookieNotice: 60,
};

export const table = {
    scrollBarWidth: `9px`,
    breakContainerSpacing: `25px`,
    breakContainerOffset: `18px`,
    breakpoints: {
        filterColumnWrap: `490px`,
    },
};

const theme = {
    breakpoints,
    breakpointNumbers,
    button,
    colors,
    fontFamily,
    gradients,
    iconLinkColors,
    layout,
    palette,
    sectionBackgroundColor,
    spacing,
    statCardColors,
    zIndex,
    table,
};

export default theme;
