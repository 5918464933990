import styled from "@emotion/styled";
import React from "react";
import PropTypes from "prop-types";

import Button from "~/components/buttons/Button";

import UserContent from "~/components/UserContent";

import { sanitizeAndParseHTML } from "~/utilities/helpers";

const TermsOfUseWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Terms = styled(UserContent)`
    max-width: 100%;
`;

const ButtonWrapper = styled.div`
    padding-top: 15px;
`;

const TermsOfUse = ({
    startDownload,
    setDocTermsStorage,
    terms,
    documentStorage,
    closeModal,
}) => (
    <TermsOfUseWrapper>
        <Terms>{sanitizeAndParseHTML(terms)}</Terms>
        <ButtonWrapper>
            <Button
                color="purple"
                small
                onClick={() => {
                    startDownload();

                    if (documentStorage) {
                        setDocTermsStorage(documentStorage);
                    }

                    if (closeModal) {
                        closeModal();
                    }
                }}
            >
                I Accept
            </Button>
        </ButtonWrapper>
    </TermsOfUseWrapper>
);

TermsOfUse.propTypes = {
    terms: PropTypes.string.isRequired,
    documentStorage: PropTypes.string.isRequired,
    startDownload: PropTypes.func.isRequired,
    setDocTermsStorage: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default TermsOfUse;
