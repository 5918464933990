import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";

import { getConfirmationMessage } from "~/utilities/formHelpers";
import { sanitizeAndParseHTML } from "~/utilities/helpers";
import PropTypes from "prop-types";

const Wrapper = styled.div`
    margin: 2rem auto 4rem auto;
    width: 100%;
`;

const Title = styled.h2`
    font-size: 3rem;
    margin-bottom: 2rem;
`;

const Message = styled.p`
    &:focus {
        outline: 1px solid;
    }
`;

const SuccessMessage = ({
    formInformation,
    successfulSubmission,
    title = "Form Submission Success",
    message = "Thank you! The form was successfully submitted.",
    id = "success-message",
}) => {
    const messageRef = useRef(null);

    useEffect(() => {
        if (successfulSubmission && messageRef.current) {
            messageRef.current.focus();
        }
    }, [successfulSubmission]);

    if (!successfulSubmission) {
        return null;
    }

    if (formInformation?.confirmations) {
        message = sanitizeAndParseHTML(
            getConfirmationMessage(formInformation.confirmations),
        );
    }

    return (
        <Wrapper aria-labelledby={id} role="alert">
            <Title id={id}>{title}</Title>
            <Message ref={messageRef} tabIndex="0">
                {message}
            </Message>
        </Wrapper>
    );
};

export default SuccessMessage;

SuccessMessage.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    successfulSubmission: PropTypes.bool.isRequired,
    formInformation: PropTypes.shape({}),
    id: PropTypes.string,
};
