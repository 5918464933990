import PropTypes from "prop-types";
import React from "react";
import { has, isString } from "lodash";

import { automaticallyDownloadFile, trackDownload } from "~/utilities/helpers";

import { useFormContext } from "~/context/FormContext";

import InlineForm from "~/components/InlineForm";

const maybeGetDocumentId = (url, title) => {
    const downloadPath = "/download/";
    const pathIndex = url.indexOf(downloadPath);
    const toStringId = url.slice(pathIndex + downloadPath.length);
    const id = Number.parseInt(toStringId, 10);
    const isNotANumber = Number.isNaN(id);

    return isNotANumber ? title : `${id}`;
};

const getRunOnSubmitActions = (url, title) => {
    if (!url || !isString(url)) {
        return null;
    }

    if (!title) {
        title = "Inline Form Download";
    }

    const id = maybeGetDocumentId(url, title);

    return {
        downloadFile: {
            doc: { title, url, id },
            isGated: true,
            openInSameTab: true,
        },
        tracking: {
            event: "gaEvent",
            values: {
                action: "Inline Form Download",
                category: "Request Software",
                label: title,
            },
        },
    };
};

const getRunOnSubmitHandler = (runOnSubmitActions) => {
    if (!runOnSubmitActions) {
        return null;
    }

    return () => {
        trackDownload(
            runOnSubmitActions.tracking.event,
            runOnSubmitActions.tracking.values,
        );
        automaticallyDownloadFile(
            runOnSubmitActions.downloadFile.doc,
            runOnSubmitActions.downloadFile.isGated,
            runOnSubmitActions.downloadFile.openInSameTab,
        );
    };
};

const getFormPresetValues = (hiddenFieldValues, runOnSubmitActions) => {
    if (!runOnSubmitActions) {
        return {};
    }

    const title = runOnSubmitActions.downloadFile.doc.title;

    if (has(hiddenFieldValues, "reference_design")) {
        return {
            reference_design: `${hiddenFieldValues.reference_design} - ${title}`,
        };
    }

    return { reference_design: title };
};

const HtmlEmbedForm = ({ form, title = "", url = "", children }) => {
    const { hiddenFieldValues } = useFormContext();

    const runOnSubmitActions = getRunOnSubmitActions(url, title);
    const runOnSubmit = getRunOnSubmitHandler(runOnSubmitActions);
    const formPresetValues = getFormPresetValues(
        hiddenFieldValues,
        runOnSubmitActions,
    );

    return (
        <InlineForm
            {...{
                form,
                title,
                runOnSubmit,
                runOnSubmitActions,
                formPresetValues,
            }}
        >
            {children}
        </InlineForm>
    );
};

HtmlEmbedForm.propTypes = {
    form: PropTypes.string.isRequired,
    children: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
};

export default HtmlEmbedForm;
