import PropTypes from "prop-types";
import React, { useState } from "react";

import Button from "~/components/buttons/Button";

import ErrorBoundary from "~/components/ErrorBoundary";
import CookieBlock from "~/queryBlocks/CookieBlock";

const InlineCookieConsent = ({ children }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <ErrorBoundary>
            <Button onClick={() => setModalIsOpen(true)} type="button" large>
                {children}
            </Button>
            <CookieBlock
                openFromButton={modalIsOpen}
                closeModalProp={() => setModalIsOpen(false)}
                inline
            />
        </ErrorBoundary>
    );
};

InlineCookieConsent.propTypes = {
    children: PropTypes.string.isRequired,
};

export default InlineCookieConsent;
