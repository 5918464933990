import React, { useState } from "react";
import { Link as GatsbyLink, navigate } from "gatsby";
import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { isNull, trim } from "lodash";

import { ScreenReaderText } from "~/styles/screenReaderText";

const AnchorLink = styled.a``;

const GatsbyJumpLink = ({ onClick, href, tagRef, ...rest }) => {
    const [smoothScroll, setSmoothScroll] = useState(false);

    return (
        <>
            {smoothScroll ? (
                <Global
                    styles={css`
                        html {
                            scroll-behavior: smooth;
                        }
                    `}
                />
            ) : null}
            <AnchorLink
                {...rest}
                href={href}
                ref={tagRef}
                onClick={(event) => {
                    event.preventDefault();
                    setSmoothScroll(true);
                    navigate(href);
                    if (onClick) {
                        onClick(event);
                    }
                }}
            />
        </>
    );
};

const isRelativeLink = (linkTo) => {
    return new RegExp(`^(/(?!/))`).test(linkTo);
};

const isHashLink = (linkTo) => {
    return /(#[a-zA-Z0-9_=-]*)/.test(linkTo);
};

const isExternalLink = ({ linkTo, escapedFrontendURL, external }) => {
    if (external) {
        return true;
    }

    if (isRelativeLink(linkTo)) {
        return false;
    }

    return !linkTo.startsWith(escapedFrontendURL);
};

const shouldOpenInNewTab = (props) => {
    const { newTabOverride } = props;

    switch (newTabOverride) {
        case "newTab":
            return true;
        case "sameTab":
            return false;
        default:
            return isExternalLink(props);
    }
};

const shouldUseGatsbyJumpLink = (props) => {
    const { linkTo } = props;

    return !shouldOpenInNewTab(props) && isHashLink(linkTo);
};

const shouldUseGatsbyLink = (props) => {
    const { linkTo } = props;

    if (shouldOpenInNewTab(props)) {
        return false;
    }

    return isRelativeLink(linkTo) && !isHashLink(linkTo);
};

const formatProps = (props) => {
    const {
        linkTo,
        className = "",
        onClick = () => {},
        ariaExpanded = null,
        ariaLabel = "",
        tagRef = null,
        onKeyDown = () => {},
        topLinkLabel = null,
        state = {},
        lastItem = false,
    } = props;

    const genericProps = { className, onClick, onKeyDown };

    if (shouldUseGatsbyJumpLink(props)) {
        genericProps.tagRef = tagRef;
    } else {
        genericProps.ref = tagRef;
    }

    if (ariaLabel) {
        genericProps["aria-label"] = ariaLabel;
    }

    if (!isNull(ariaExpanded)) {
        genericProps["aria-expanded"] = ariaExpanded;
    }

    if (!isNull(topLinkLabel)) {
        genericProps["data-parent"] = topLinkLabel;
        genericProps["data-last"] = lastItem;
    }

    if (!shouldUseGatsbyJumpLink(props)) {
        genericProps["ref"] = tagRef;
    } else {
        genericProps["tagRef"] = tagRef;
    }

    if (shouldUseGatsbyLink(props)) {
        return {
            to: linkTo,
            state: { ...state, isGatsbyLink: true },
            ...genericProps,
        };
    } else {
        const newTabAttributes = {};
        if (shouldOpenInNewTab(props)) {
            newTabAttributes.target = "_blank";
            newTabAttributes.rel = "noopener noreferrer";
        }
        return {
            href: linkTo,
            ...newTabAttributes,
            ...genericProps,
        };
    }
};

const BaseLink = ({ to, screenReaderText = "", children, ...rest }) => {
    const escapedFrontendURL = trim(process.env.GATSBY_SITE_URL);
    const linkTo = trim(to) || `${escapedFrontendURL}/`;
    const updatedProps = { linkTo, escapedFrontendURL, ...rest };
    const formattedProps = formatProps(updatedProps);
    let Link;

    if (shouldUseGatsbyLink(updatedProps)) {
        Link = GatsbyLink;
    } else if (shouldUseGatsbyJumpLink(updatedProps)) {
        Link = GatsbyJumpLink;
    } else {
        Link = AnchorLink;
    }

    return (
        <Link {...formattedProps}>
            {children}
            {screenReaderText ? (
                <ScreenReaderText>{screenReaderText}</ScreenReaderText>
            ) : null}
        </Link>
    );
};

BaseLink.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    external: PropTypes.bool,
    to: PropTypes.string.isRequired,
    newTabOverride: PropTypes.string,
    onClick: PropTypes.func,
    ariaExpanded: PropTypes.bool,
    ariaLabel: PropTypes.string,
    screenReaderText: PropTypes.string,
    onKeyDown: PropTypes.func,
    tagRef: PropTypes.object,
    topLinkLabel: PropTypes.string,
    state: PropTypes.object,
    lastItem: PropTypes.bool,
};

export default BaseLink;
