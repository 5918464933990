import { ErrorMessage } from "@hookform/error-message";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import React from "react";
import { trim, toString } from "lodash";

import {
    getErrorMessage,
    ariaAlert,
    isInvalidISPEmail,
} from "~/utilities/formHelpers";

export const Label = styled.label`
    font-size: 20px;
    color: ${({ theme }) => theme.palette.black};
    display: ${({ hidden }) => (hidden ? "none" : null)};
`;

export const inputStyles = ({ theme, disabled, backgroundColor, error }) => css`
    background-color: ${disabled
        ? theme.palette.grayFour
        : backgroundColor === "white"
          ? theme.palette.white
          : theme.palette.grayThree};
    width: 100%;
    border: 2px solid ${theme.palette.grayOne};
    padding: 10px;
    cursor: ${disabled ? `not-allowed` : null};

    ::placeholder {
        color: ${theme.palette.grayOne};
        opacity: 1;
    }

    &:focus,
    &:focus-visible {
        border: ${error
            ? `2px solid ${theme.palette.red}`
            : `2px solid ${theme.palette.black}`};
        outline: none;
    }
`;

const StyledInput = styled.input`
    ${inputStyles};
`;

export const errorMessageStyles = ({ theme }) => css`
    color: ${theme.palette.red};
    font-size: 1.6rem;
    font-weight: 600;
    display: block;
`;

export const Error = styled.span`
    ${errorMessageStyles};
`;

const isRequired = (value, requiredMsg) => {
    const isValid = trim(toString(value));

    if (isValid) {
        return null;
    }

    return requiredMsg;
};

const getValidationRules = (required, errorMessage, label, type, formId) => {
    if (!required) {
        return { required: false };
    }

    const requiredMsg = getErrorMessage(errorMessage, label);

    const validationRules = {
        required: requiredMsg,
        validate: (value) => isRequired(value, requiredMsg),
    };

    if (type === "email") {
        validationRules.pattern = {
            value: /\S+@\S+\.\S+/,
            message: "Entered value does not match email format",
        };
        validationRules.validate = (email) => {
            const isRequiredMsg = isRequired(email, requiredMsg);
            const isISPErrMsg = isInvalidISPEmail(formId, email);

            if (isRequiredMsg) {
                return isRequiredMsg;
            }

            if (isISPErrMsg) {
                return isISPErrMsg;
            }

            return null;
        };
    }

    return validationRules;
};

const Input = ({
    errors,
    label,
    name,
    register,
    formId,
    placeholder = "",
    required = false,
    type = "text",
    disabled = false,
    backgroundColor = "gray",
    autocompleteAttribute = "on",
    errorMessage = "",
}) => (
    <>
        <Label htmlFor={name} hidden={type === "hidden"}>
            {label}
            {required ? <span>*</span> : ""}
        </Label>
        <StyledInput
            {...{
                type: type === "phone" ? "tel" : type,
                placeholder,
                name,
                disabled,
                backgroundColor,
            }}
            {...register(
                name,
                getValidationRules(required, errorMessage, label, type, formId),
            )}
            autoComplete={autocompleteAttribute}
            error={errors[name]}
            id={name}
            aria-required={required}
            aria-label={ariaAlert(label, errors[name])}
        />

        {type !== "hidden" ? (
            <ErrorMessage
                {...{ errors, name }}
                render={({ message }) => <Error>{message}</Error>}
            />
        ) : null}
    </>
);

Input.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    formId: PropTypes.number.isRequired,
    errors: PropTypes.object,
    type: PropTypes.oneOf(["text", "email", "num", "phone", "date", "hidden"])
        .isRequired,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    backgroundColor: PropTypes.oneOf(["white", "gray"]),
    autocompleteAttribute: PropTypes.string,
    errorMessage: PropTypes.string,
};

export default Input;
