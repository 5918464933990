import PropTypes from "prop-types";
import React from "react";

import GatedDocumentButton from "~/components/buttons/GatedDocumentButton";

import useAllDownloadData from "~/hooks/data/useAllDownloadData";

const InlineDocument = ({ databaseId, children }) => {
    const { allWpDlmdownload } = useAllDownloadData();
    const documentToShow = allWpDlmdownload.nodes.find(
        (doc) => doc.databaseId === parseInt(databaseId, 10),
    );
    return documentToShow ? (
        <GatedDocumentButton doc={documentToShow} inline>
            {children}
        </GatedDocumentButton>
    ) : null;
};

InlineDocument.propTypes = {
    databaseId: PropTypes.string.isRequired,
};

export default InlineDocument;
