import styled from "@emotion/styled";
import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

const Item = styled.li`
    line-height: 1.4;
`;

const Input = styled.input`
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
`;

const Label = styled.label`
    font-size: 1.8rem;
    font-weight: ${({ selected }) => (selected ? 700 : 300)};
    text-align: left;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &:focus-within {
        outline: 3px solid ${({ theme }) => theme.palette.focusBlue};
        outline-offset: 5px;
    }
`;

const FilterListItem = ({
    name,
    slug,
    count,
    selected,
    handleFilter,
    typeName,
    isLanguageFilter,
    linkUrl,
    setActiveCode,
}) => {
    return (
        <Item>
            <Label selected={selected}>
                <Input
                    type="checkbox"
                    checked={selected}
                    onChange={() => {
                        if (isLanguageFilter) {
                            setActiveCode();
                            navigate(linkUrl, {
                                state: {
                                    disableScrollUpdate: true,
                                },
                            });
                        } else {
                            handleFilter(typeName, slug);
                        }
                    }}
                />
                {`${name} (${count})`}
            </Label>
        </Item>
    );
};

FilterListItem.propTypes = {
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
    handleFilter: PropTypes.func.isRequired,
    typeName: PropTypes.string.isRequired,
    setActiveCode: PropTypes.func,
};

export default FilterListItem;
