import { css } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import { sectionBackgroundColor } from "~/theme";

const getBackgroundColor = ({ backgroundColor, theme }) => {
    return css`
        background-color: ${theme.sectionBackgroundColor[backgroundColor]};
    `;
};

const getPadding = ({
    withPadding,
    withSmallerPadding,
    withBorder,
    withBorderBottom,
    theme,
}) => {
    let padding = ``;
    if (withBorder) {
        padding += `
            border-top: 1px solid ${theme.palette.borderGray};
            padding-top: ${theme.spacing.extraLarge};
        `;
    }

    if (withBorderBottom) {
        padding += `
            border-bottom: 1px solid ${theme.palette.borderGray};
            padding-bottom: ${theme.spacing.extraLarge};
        `;
    }

    if (withPadding) {
        padding += `
            padding: ${theme.spacing.extraLarge} 0;
        `;
    }

    if (withSmallerPadding) {
        padding += `
            padding: ${theme.spacing.smaller} 0;
        `;
    }

    return css`
        ${padding}
    `;
};

const Wrapper = styled.section`
    ${getBackgroundColor};
    ${getPadding};
    overflow: ${({ hideOverflow }) => (hideOverflow ? `hidden` : `visible`)};
    margin-bottom: ${({ noMarginBottom, theme }) =>
        noMarginBottom ? `0` : `calc(${theme.layout.sectionMargin} / 2)`};
    display: ${({ flex }) => (flex ? "flex" : "block")};
    width: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoints.mobileLandscape}) {
        margin-bottom: ${({ noMarginBottom, theme }) =>
            noMarginBottom ? `0` : theme.layout.sectionMargin};
    }
`;

const Div = Wrapper.withComponent("div");

const SectionWrapper = ({
    backgroundColor = "white",
    children,
    element = "section",
    noMarginBottom = false,
    withPadding = false,
    withSmallerPadding = false,
    withBorder = false,
    withBorderBottom = false,
    sectionId = "",
    hideOverflow = false,
    flex = false,
    forwardRef = null,
    ...rest
}) => {
    const Element = element === "section" ? Wrapper : Div;

    return (
        <Element
            id={sectionId}
            {...{
                backgroundColor,
                noMarginBottom,
                withPadding,
                withSmallerPadding,
                withBorder,
                withBorderBottom,
                hideOverflow,
                flex,
                ref: forwardRef,
                ...rest,
            }}
        >
            {children}
        </Element>
    );
};

SectionWrapper.propTypes = {
    backgroundColor: PropTypes.oneOf(Object.keys(sectionBackgroundColor)),
    children: PropTypes.node.isRequired,
    element: PropTypes.oneOf(["section", "div"]),
    noMarginBottom: PropTypes.bool,
    withPadding: PropTypes.bool,
    withSmallerPadding: PropTypes.bool,
    withBorder: PropTypes.bool,
    withBorderBottom: PropTypes.bool,
    hideOverflow: PropTypes.bool,
    flex: PropTypes.bool,
    sectionId: PropTypes.string,
    forwardRef: PropTypes.object,
};

export default SectionWrapper;
