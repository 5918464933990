import { css } from "@emotion/react";
import styled from "@emotion/styled";

const justifyContent = ({ align }) => {
    switch (align) {
        case "center":
            return css`
                justify-content: center;
            `;
        case "right":
            return css`
                justify-content: flex-end;
            `;
        case "space-between":
            return css`
                justify-content: space-between;
            `;
        default:
            return css`
                justify-content: flex-start;
            `;
    }
};

const alignItems = ({ verticalAlignment }) => {
    switch (verticalAlignment) {
        case "bottom":
            return css`
                align-items: flex-end;
            `;
        case "top":
            return css`
                align-items: flex-start;
            `;
        default:
            return css`
                align-items: center;
            `;
    }
};

const ButtonGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${({ orientation }) =>
        orientation === "vertical" ? `column` : `row`};
    gap: ${({ halfGutter }) => (halfGutter ? `12px` : `24px`)};
    margin-bottom: ${({ theme, withMarginBottom }) =>
        withMarginBottom ? theme.layout.paragraphSpacing : 0};
    ${justifyContent};
    ${alignItems};
`;

export default ButtonGroup;
