import React, { cloneElement } from "react";

import Button from "~/components/buttons/Button";
import PropTypes from "prop-types";

const FormModalButton = ({
    onClick,
    largeButton = false,
    alignButton = "left",
    buttonLabel = "",
    altButton = null,
}) => {
    return (
        <div style={{ textAlign: alignButton }}>
            {altButton ? (
                cloneElement(altButton, { onClick })
            ) : (
                <Button large={largeButton} onClick={onClick}>
                    {buttonLabel}
                </Button>
            )}
        </div>
    );
};

export default FormModalButton;

FormModalButton.propTypes = {
    altButton: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]),
    alignButton: PropTypes.string,
    buttonLabel: PropTypes.string,
    largeButton: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};
