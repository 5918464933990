const transition = (
    { speed = 200, easingFunction = "ease-in-out", delay = 0 },
    ...transitionProperties
) => {
    const properties = [...transitionProperties];

    if (properties.length) {
        const transitions = properties.map(
            (property) => `${property} ${speed}ms ${easingFunction} ${delay}ms`,
        );

        return {
            transition: transitions.join(", "),
        };
    }

    return null;
};

export default transition;
