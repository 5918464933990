import React from "react";
import styled from "@emotion/styled";

import TextLink from "~/components/links/TextLink";

const RecaptchaText = styled.div`
    font-size: 1.6rem;
`;

const RecaptchaLink = styled(TextLink)`
    @media (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
        font-size: 1.6rem;
    }
`;

const RecaptchaTermsAndConditions = () => {
    return (
        <RecaptchaText>
            This site is protected by reCAPTCHA and the Google{" "}
            <RecaptchaLink external to="https://policies.google.com/privacy">
                Privacy Policy
            </RecaptchaLink>{" "}
            and{" "}
            <RecaptchaLink external to="https://policies.google.com/terms">
                Terms of Service
            </RecaptchaLink>{" "}
            apply.
        </RecaptchaText>
    );
};

export default RecaptchaTermsAndConditions;
