import { ErrorMessage } from "@hookform/error-message";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import { camelCase, isEmpty } from "lodash";

import UserContent from "~/components/UserContent";
import ErrorBoundary from "~/components/ErrorBoundary";

import FaIcon from "~/components/FaIcon";

import { Error, Label } from "~/components/FormElements/Input";

import {
    OptionWrapper,
    Input,
    GroupedOptionWrapper,
    DynamicFieldWrapper,
} from "~/components/FormElements/Checkbox";

import transition from "~/utilities/transition";
import { sanitizeAndParseHTML } from "~/utilities/helpers";
import {
    getLegend,
    getErrorMessage,
    getAriaLabelWithText,
} from "~/utilities/formHelpers";

export const CircleIcon = styled(FaIcon)`
    ${transition({}, "opacity")};
    opacity: 0;
    font-size: 10px;
    position: absolute;
    top: 6px;
    left: 6px;
    color: ${({ theme }) => theme.palette.white};
    cursor: ${({ disabled }) => (disabled ? `not-allowed` : "pointer")};
`;

export const RadioLabel = styled(Label)`
    font-size: 1.8rem;
    cursor: ${({ disabled }) => (disabled ? `not-allowed` : "pointer")};
    display: flex;

    &:before {
        content: "";
        height: 20px;
        width: 20px;
        background-color: ${({ theme }) => theme.palette.white};
        cursor: ${({ disabled }) => (disabled ? `not-allowed` : "pointer")};
        margin-right: 10px;
        padding: 10px;
        border: ${({ theme }) => `1px solid ${theme.palette.grayTwo}`};
        border-radius: 50%;
        position: relative;
    }

    ${Input}:checked ~ & {
        &:before {
            border: ${({ theme }) => `1px solid ${theme.palette.grayThree}`};
            background-color: ${({ theme }) => theme.palette.grayTwo};
        }

        ${CircleIcon} {
            opacity: 1;
        }
    }

    ${Input}:focus-visible + & {
        outline: ${({ theme }) => `1px solid ${theme.palette.black}`};
    }

    @supports not selector(:focus-visible) {
        ${Input}:focus + & {
            outline: ${({ theme }) => `1px solid ${theme.palette.black}`};
        }
    }
`;

export const RadioWrapper = styled(OptionWrapper)`
    margin-right: 20px;
`;

const GroupedRadioWrapper = styled(GroupedOptionWrapper)`
    margin-right: 20px;
`;

export const OptionsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const Legend = styled.legend`
    margin-bottom: 5px;
`;

const DynamicOptionWrapper = ({ optionsLength, children, ...props }) => {
    let OptionWrapperReturn = (
        <RadioWrapper {...props}>{children}</RadioWrapper>
    );
    if (optionsLength > 1) {
        OptionWrapperReturn = (
            <GroupedRadioWrapper {...props}>{children}</GroupedRadioWrapper>
        );
    }
    return OptionWrapperReturn;
};

const Radio = ({
    label,
    name,
    register,
    labelPlacement,
    options,
    errors = null,
    required = false,
    disabled = false,
    errorMessage = "",
}) => {
    if (isEmpty(options)) {
        return null;
    }
    const optionsLength = options?.length;
    return (
        <ErrorBoundary>
            {getLegend(labelPlacement, required, label)}
            <DynamicFieldWrapper
                aria-labelledby={`${camelCase(label)}FieldLabel`}
                required={required}
                {...{ optionsLength }}
            >
                <OptionsWrapper>
                    {options.map(({ text, value }, index) => (
                        <DynamicOptionWrapper
                            {...{ optionsLength }}
                            aria-required={required}
                            key={index}
                        >
                            <Input
                                {...{ name, disabled, value }}
                                {...register(name, {
                                    required: required
                                        ? getErrorMessage(errorMessage, label)
                                        : false,
                                })}
                                type="radio"
                                id={`${name}_${index}`}
                                name={name}
                                aria-required={required}
                                aria-label={getAriaLabelWithText(
                                    label,
                                    text,
                                    errors[name],
                                )}
                            />
                            <RadioLabel
                                htmlFor={`${name}_${index}`}
                                {...{ disabled }}
                                error={errors[`${name}_${index}`]}
                            >
                                <CircleIcon {...{ disabled }} name="circle" />
                                <UserContent>
                                    {required &&
                                    labelPlacement === "hidden_label" ? (
                                        <span>*</span>
                                    ) : (
                                        ""
                                    )}
                                    {sanitizeAndParseHTML(text)}
                                </UserContent>
                            </RadioLabel>
                        </DynamicOptionWrapper>
                    ))}
                </OptionsWrapper>

                <ErrorMessage
                    {...{ errors, name }}
                    render={({ message }) => <Error>{message}</Error>}
                />
            </DynamicFieldWrapper>
        </ErrorBoundary>
    );
};

Radio.propTypes = {
    errors: PropTypes.shape({}),
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
};

export default Radio;
