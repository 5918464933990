import React, { useEffect, useMemo, useState } from "react";
import { Script } from "gatsby";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import ErrorBoundary from "~/components/ErrorBoundary";

import FormModalButton from "~/components/FormExtras/FormModalButton";
import ConditionalModalWrapper from "~/components/FormExtras/ConditionalModalWrapper";
import FormContainer from "./components/FormContainer";

import useAllFormData from "~/hooks/data/useAllFormData";

import { trackCustomGA4Event } from "~/utilities/trackCustomEvent";

const useGoogleReCaptcha = () => {
    const [isReady, setIsReady] = useState(false);

    let executeRecaptcha = null;

    if (isReady) {
        executeRecaptcha = async (action) => {
            return await window.grecaptcha.execute(
                process.env.GATSBY_RECAPTCHA_SITE_KEY,
                {
                    action,
                },
            );
        };
    }

    return { executeRecaptcha, setIsReady };
};

const Form = ({
    children,
    formId = null,
    buttonLabel = "",
    largeButton = false,
    alignButton = "left",
    altButton,
    modalForm = false,
    useChildButton = false,
    isModalOpenProp = false,
    closeModalProp = null,
    hideModalButton = false,
    formPresetErrors: initialFormPresetErrors,
    runOnSubmit = null,
    formPresetValues = {},
    beforeSubmit = null,
    sendJSON = true,
    runOnSubmitActions = null,
    runBeforeSubmitActions = null,
    isOn24Form = false,
    on24EventId = "",
    on24EventKey = "",
    ...rest
}) => {
    const additionalProps = {
        runOnSubmit,
        formPresetValues,
        beforeSubmit,
        sendJSON,
        runOnSubmitActions,
        runBeforeSubmitActions,
        isOn24Form,
        on24EventId,
        on24EventKey,
        ...rest,
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [formPresetErrors, setFormPresetErrors] = useState(
        initialFormPresetErrors,
    );

    const { getFormById } = useAllFormData();

    const { executeRecaptcha, setIsReady } = useGoogleReCaptcha();

    const formInformation = getFormById(formId);
    const formTitle = formInformation?.title ?? "";

    const isOpen = useMemo(
        () => isModalOpen || isModalOpenProp,
        [isModalOpen, isModalOpenProp],
    );

    useEffect(() => {
        if (!isOpen && modalForm) {
            setSuccess(false);
            setFormPresetErrors(null);
        }
    }, [isOpen, modalForm]);

    useEffect(() => {
        if (isModalOpen || isModalOpenProp) {
            trackCustomGA4Event("formModalClick", {
                formId,
            });
        }
    }, [isModalOpen, isModalOpenProp, formId]);

    const closeModal = () => {
        if (closeModalProp) {
            closeModalProp();
        } else {
            setIsModalOpen(false);
        }
    };

    let formModalButton = null;

    if (modalForm && !useChildButton && !hideModalButton) {
        formModalButton = (
            <FormModalButton
                {...{
                    altButton,
                    alignButton,
                    buttonLabel,
                    largeButton,
                }}
                onClick={() => {
                    setIsModalOpen(true);
                }}
            />
        );
    } else if (useChildButton) {
        formModalButton = children;
    }

    return (
        <ErrorBoundary>
            {formModalButton}

            <ConditionalModalWrapper
                modalForm={modalForm}
                isOpen={isOpen}
                onRequestClose={closeModal}
                formTitle={formTitle}
            >
                {isEmpty(formInformation) ? (
                    <h2>Form could not be loaded.</h2>
                ) : (
                    <FormContainer
                        {...{
                            ...additionalProps,
                            uniqueFormId: formId,
                            formInformation,
                            formId,
                            setFormPresetErrors,
                            formPresetErrors,
                            setSuccess,
                            success,
                            executeRecaptcha,
                        }}
                    />
                )}
            </ConditionalModalWrapper>

            <Script
                src={`https://www.recaptcha.net/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_SITE_KEY}`}
                onLoad={() => {
                    window.grecaptcha.ready(() => {
                        setIsReady(true);
                    });
                }}
            />
        </ErrorBoundary>
    );
};

Form.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]),
    formId: PropTypes.number,
    runOnSubmit: PropTypes.func,
    buttonLabel: PropTypes.string,
    modalForm: PropTypes.bool,
    hideModalButton: PropTypes.bool,
    isOn24Form: PropTypes.bool,
    on24EventId: PropTypes.string,
    on24EventKey: PropTypes.string,
    formPresetValues: PropTypes.shape({}),
    largeButton: PropTypes.bool,
    alignButton: PropTypes.string,
    altButton: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]),
    useChildButton: PropTypes.bool,
    isModalOpenProp: PropTypes.bool,
    closeModalProp: PropTypes.func,
    formPresetErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    beforeSubmit: PropTypes.func,
    sendJSON: PropTypes.bool,
    runBeforeSubmitActions: PropTypes.shape({
        createPDF: PropTypes.shape({
            fieldName: PropTypes.string,
            table: PropTypes.object,
            filename: PropTypes.string,
            type: PropTypes.string,
        }),
    }),
    runOnSubmitActions: PropTypes.shape({
        saveToPardotStorage: PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.any,
        }),
        saveToLocalStorage: PropTypes.oneOfType([
            PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.string,
                    value: PropTypes.any,
                }),
            ),
            PropTypes.shape({ key: PropTypes.string, value: PropTypes.any }),
        ]),
        downloadFile: PropTypes.shape({
            doc: PropTypes.object,
            isGated: PropTypes.bool,
            openInSameTab: PropTypes.bool,
        }),
        tracking: PropTypes.shape({
            event: PropTypes.string,
            values: PropTypes.object,
        }),
        navigate: PropTypes.shape({
            label: PropTypes.string,
            url: PropTypes.string,
        }),
    }),
};

export default Form;
