import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { config, library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

config.autoAddCss = false;

library.add(fas, fab, far);

const FaIcon = ({ iconLibrary = "fas", name = "", className }) => {
    if (!name) return null;

    return <FontAwesomeIcon {...{ className }} icon={[iconLibrary, name]} />;
};

FaIcon.propTypes = {
    name: PropTypes.string,
    iconLibrary: PropTypes.oneOf(["fab", "fas", "far"]),
};

export default FaIcon;
