import React, {
    useEffect,
    useCallback,
    useContext,
    useState,
    createContext,
} from "react";
import { graphql, useStaticQuery } from "gatsby";
import { forEach, has, map, reduce } from "lodash";

import useAllFormData from "~/hooks/data/useAllFormData";
import useUrlManager from "~/hooks/useUrlManager";

import { usePardotUserContext } from "./PardotUserContext";

import { UTM_VARIABLES } from "~/utilities/helpers";

import {
    getLocalStorageSerialize,
    setLocalStorageItem,
    setLocalStorageSerialize,
} from "~/utilities/localStorageHelpers";

import {
    getPresetFormValues,
    submitFormData,
    formatDataForSubmission,
    isInvalidISPEmail,
    saveInvalidEmailDomain,
    FormSubmissionException,
} from "~/utilities/formHelpers";

const FormContext = createContext();

const addProspectId = (downloadValues, userData) => {
    return reduce(
        downloadValues,
        (acc, item, key) => {
            if (key.includes("downloadHistory")) {
                try {
                    const jsonData = JSON.parse(item);

                    const downloadData = map(jsonData, (download) => {
                        const prospectId = userData.prospect_id;
                        return {
                            ...download,
                            id: prospectId,
                        };
                    });

                    const newJsonData = JSON.stringify(downloadData);

                    return {
                        ...acc,
                        [key]: newJsonData,
                    };
                } catch (e) {
                    console.error(e.message);
                    return {
                        ...acc,
                        [key]: item,
                    };
                }
            }

            return {
                ...acc,
                [key]: item,
            };
        },
        {},
    );
};

const shouldSubmit = (downloadFormId, userData) => {
    const downloadHistory = getLocalStorageSerialize("downloadHistory", []);
    const isInValidEmail = isInvalidISPEmail(downloadFormId, userData.email);

    return (
        downloadFormId &&
        has(userData, "email") &&
        has(userData, "prospect_id") &&
        downloadHistory.length &&
        !isInValidEmail
    );
};

export const FormProvider = ({ children }) => {
    const [hiddenFieldValues, setHiddenFieldValues] = useState({});

    const { getQueryParams } = useUrlManager();
    const queryVariables = getQueryParams();

    const { getFormById } = useAllFormData();
    const { userData } = usePardotUserContext();

    const {
        wp: { optionsPage, formsOptionsPage },
    } = useStaticQuery(graphql`
        query StaticFormContextQuery {
            wp {
                optionsPage {
                    globalOptions {
                        gravityForms
                    }
                }
                formsOptionsPage {
                    downloadHistoryFormId {
                        formId
                    }
                }
            }
        }
    `);

    const checkPostData = useCallback(() => {
        const downloadFormId =
            formsOptionsPage?.downloadHistoryFormId?.formId || "";
        const gravityFormsEndpoint =
            optionsPage?.globalOptions?.gravityForms || "";
        const downloadFormInformation = getFormById(downloadFormId);

        if (shouldSubmit(downloadFormId, userData)) {
            // Map pardot userData, hidden field values and local storage to any available form fields
            const downloadValues = userData
                ? getPresetFormValues(
                      downloadFormInformation,
                      userData,
                      hiddenFieldValues,
                      downloadFormId,
                  )
                : null;

            const dataWithProspectId = addProspectId(downloadValues, userData);

            const formattedData = formatDataForSubmission(
                dataWithProspectId,
                downloadFormInformation,
            );

            const postDownloadData = async () => {
                return await submitFormData(
                    downloadFormId,
                    formattedData,
                    true,
                    gravityFormsEndpoint,
                );
            };

            postDownloadData()
                .then((formSubmission) => {
                    if (formSubmission.code === "success") {
                        setLocalStorageSerialize("downloadHistory", []);
                    } else {
                        const formData = {
                            ...formattedData,
                            formId: downloadFormId,
                        };

                        const error = new FormSubmissionException(
                            formSubmission,
                            formData,
                        );

                        saveInvalidEmailDomain(
                            error.errorMessage,
                            error.formData,
                        );

                        console.error(error.errorMessage);
                    }
                })
                .catch((err) => {
                    console.error(`Issues postDownloadData - ${err.message}`);
                });
        }
    }, [
        userData,
        getFormById,
        hiddenFieldValues,
        optionsPage?.globalOptions?.gravityForms,
        formsOptionsPage?.downloadHistoryFormId?.formId,
    ]);

    useEffect(() => {
        checkPostData();
    }, [checkPostData]);

    useEffect(() => {
        if (queryVariables) {
            forEach(UTM_VARIABLES, (utm) => {
                if (queryVariables[utm]) {
                    setLocalStorageItem(utm, queryVariables[utm]);
                }
            });
        }
    }, [queryVariables]);

    const downloadFormId =
        formsOptionsPage?.downloadHistoryFormId?.formId || "";

    return (
        <FormContext.Provider
            value={{
                hiddenFieldValues,
                setHiddenFieldValues,
                downloadFormId,
                checkPostData,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

export const useFormContext = () => {
    const context = useContext(FormContext);

    if (context === undefined) {
        throw new Error("useFormContext must be used within a FormProvider");
    }

    return context;
};
