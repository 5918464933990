import { graphql, useStaticQuery } from "gatsby";
import { useMemo } from "react";

const useAllDownloadData = () => {
    const { allWpDlmdownload } = useStaticQuery(graphql`
        query {
            allWpDlmdownload {
                nodes {
                    ...DocumentFragment
                }
            }
        }
    `);

    return useMemo(() => {
        return {
            allWpDlmdownload,
        };
    }, [allWpDlmdownload]);
};

export default useAllDownloadData;
